<script lang="ts">
import {
  defineComponent,
  ref,
  getCurrentInstance,
  computed,
  PropType,
  watch,
  onUnmounted,
  nextTick,
} from 'vue';
import { useUserStore } from '@/store';
import {
  useGetClientViewCompaniesQuery,
  GetClientViewCompaniesQuery,
  useGetClientViewCompanyQuery,
} from '@/types/graphql';
import { getTranslationTerms } from '@/utils/composable/localeHelper';
import BaseSearch from '@/components/base/BaseSearch.vue';
import BaseSkeletonLoader from '@/components/base/BaseSkeletonLoader.vue';
import { ClientCompanyClientView } from '@/types/navigation';
import ClickButton from '@/components/base/ClickButton.vue';
import { COMMON_SIDE_BAR_ROUTES } from '@/config/constants/routes';
import { addScrollEndListener, removeScrollEndListener } from '@/utils/eventListeners';
import BaseLoader from '@/components/base/BaseLoader.vue';
import { userRoles, frontendRolesToBackendRoles } from '@/config/user';

export default defineComponent({
  name: 'ClientsList',
  components: {
    BaseLoader,
    BaseSearch,
    BaseSkeletonLoader,
    ClickButton,
  },
  props: {
    dataCy: { type: String, required: true },
    company: {
      type: Object as PropType<ClientCompanyClientView>,
      default: () => ({ id: '', legalName: '' }),
    },
    inCard: { type: Boolean, default: true },
    hideTitle: { type: Boolean, default: false },
    filteredByModule: { type: String, default: '' },
    isDashboard: { type: Boolean, default: false },
    filteredByStatus: { type: Array as PropType<string[]>, default: () => [''] },
  },
  emits: ['on-client-select'],
  setup(props, { emit }) {
    const cypressDataAttribute = `${getCurrentInstance()?.type.name}-${props?.dataCy}`;
    const userStore = useUserStore();
    const getComponentTerms = getTranslationTerms.bind(null, 'base', 'clientsList');
    const getProductName = getTranslationTerms.bind(null, 'products', 'product');
    const product = computed(() => userStore.getSelectedProduct);
    const searchValue = ref('');
    const selectedCompany = ref<{ id: string; legalName: string }>(props.company);
    const clientCompanies = ref<GetClientViewCompaniesQuery>();
    let fetchNextCompanies: null | (() => void);
    let refetchCompanies: null | ((search: string) => void);
    const loading = ref(false);
    const scrollLoading = ref(false);
    const clientListListRef = ref<Element | null>(null);
    const loadingId = ref('');

    const lastPage = computed(
      () => clientCompanies.value?.clientCompanies?.paginationInfo.lastPage,
    );

    const totalCount = computed(
      () => clientCompanies.value?.clientCompanies?.paginationInfo.totalCount,
    );
    const page = ref(1);
    const handleCompaniesSearch = (searchTerm: string) => {
      if (refetchCompanies) {
        refetchCompanies(searchTerm);
      }
    };
    const handleContiniousScroll = () => {
      if (fetchNextCompanies && !scrollLoading.value) {
        fetchNextCompanies();
      }
    };

    const hasActiveScrollbar = () => {
      if (clientListListRef?.value) {
        return clientListListRef?.value?.scrollHeight > clientListListRef?.value?.clientHeight;
      }
      return false;
    };

    const isRegularSARole = computed(
      () =>
        userStore.roles?.includes(
          frontendRolesToBackendRoles[userRoles.ROLE_SUSTAINABILITY_ADVISOR],
        ) && !userStore.getSelectedProduct?.isProductManager,
    );

    const getCompanies = () => {
      loading.value = true;
      const { fetchMore, refetch, onResult } = useGetClientViewCompaniesQuery(
        () => ({
          client: isRegularSARole.value ? 'direct' : null,
          product: product?.value?.id ?? '',
          clientByProduct: product?.value?.id ?? '',
          status_list: props.filteredByStatus,
          module: props.filteredByModule,
          itemsPerPage: 15,
        }),
        () => ({
          enabled: userStore.isUserLoggedIn,
          context: {
            headers: {
              'client-view': 0,
              'Client-View-Company-Id': '',
            },
          },
        }),
      );

      onResult((result) => {
        if (!result.loading) {
          clientCompanies.value = result.data;
          loading.value = false;
          nextTick(() => {
            addScrollEndListener('.client-list__list', handleContiniousScroll);

            if (!hasActiveScrollbar()) {
              handleContiniousScroll();
            }
          });
        }
      });

      fetchNextCompanies = async () => {
        if (
          lastPage.value &&
          page.value < lastPage.value &&
          (clientCompanies.value?.clientCompanies?.collection?.length || 0) <
            (totalCount.value || 0)
        ) {
          page.value++;
          scrollLoading.value = true;
          await fetchMore({
            variables: {
              page: page.value,
            },
            updateQuery: (previousQueryResult, { fetchMoreResult }) => {
              if (!fetchMoreResult) return previousQueryResult;

              return {
                ...previousQueryResult,
                clientCompanies: {
                  __typename: fetchMoreResult.clientCompanies?.__typename,
                  paginationInfo: {
                    __typename: fetchMoreResult.clientCompanies?.paginationInfo.__typename,
                    lastPage: fetchMoreResult.clientCompanies?.paginationInfo.lastPage || 1,
                    totalCount: fetchMoreResult.clientCompanies?.paginationInfo.totalCount || 1,
                  },
                  collection: [
                    ...(previousQueryResult.clientCompanies?.collection || []),
                    ...(fetchMoreResult.clientCompanies?.collection || []),
                  ],
                },
              };
            },
          });
          nextTick(() => {
            scrollLoading.value = false;
          });
        }
      };

      refetchCompanies = (searchTerm: string) => {
        page.value = 1;
        refetch({ search: searchTerm, page: page.value });
      };
    };
    getCompanies();

    const filteredClients = computed(() => {
      if (!clientCompanies.value?.clientCompanies?.collection?.length) return [];
      return clientCompanies.value.clientCompanies.collection.map((item) => ({
        legalName: item?.legalName || '',
        id: item?.id || '',
      }));
    });

    const onClientSelect = (item: { id: string; legalName: string }) => {
      selectedCompany.value = item;
      loadingId.value = item.id;

      const { onResult } = useGetClientViewCompanyQuery(
        {
          id: item.id,
        },
        {
          context: {
            headers: {
              'client-view': 0,
              'Client-View-Company-Id': '',
            },
          },
        },
      );

      onResult((result) => {
        if (!result.loading) {
          loadingId.value = '';
          emit(
            'on-client-select',
            {
              legalName: result.data.clientCompany?.legalName || '',
              id: result.data.clientCompany?.id || '',
              products: result.data.clientCompany?.products?.edges?.map(
                (companyProduct) => companyProduct?.node?.product?.id || '',
              ),
              allowedModules:
                result.data.clientCompany?.products?.edges
                  ?.filter(
                    (companyProduct) => companyProduct?.node?.product?.id === product.value?.id,
                  )[0]
                  ?.node?.allowedModules?.edges?.map(
                    (companyModule) => companyModule?.node?.slug || '',
                  ) ?? [],
            },
            result.data.clientCompany?.products?.edges?.find(
              (companyProduct) => companyProduct?.node?.product?.id === product.value?.id,
            )?.node?.defaultDashboardGroup?.slug || '',
          );
        }
      });
    };

    watch(
      () => searchValue.value,
      (newValue) => {
        if (!newValue) page.value = 1;
        handleCompaniesSearch(newValue);
      },
    );

    onUnmounted(() => {
      removeScrollEndListener('.client-list__list', handleContiniousScroll);
    });

    return {
      searchValue,
      loading,
      clientCompanies,
      filteredClients,
      product,
      selectedCompany,
      getComponentTerms,
      onClientSelect,
      cypressDataAttribute,
      getProductName,
      COMMON_SIDE_BAR_ROUTES,
      userStore,
      scrollLoading,
      hasActiveScrollbar,
      loadingId,
      clientListListRef,
    };
  },
});
</script>

<template>
  <div :class="['client-list', { 'client-list--in-card': inCard }]">
    <div class="client-list__header">
      <h3 v-if="!hideTitle" class="client-list__title">
        {{ getProductName(product?.abbr || '') }} {{ getComponentTerms('title') }}
      </h3>
      <ClickButton
        v-if="isDashboard && clientCompanies?.clientCompanies?.collection?.length"
        key="client-list-viewall"
        status="transparent icon-right-side"
        show-arrow
        :router-link="
          COMMON_SIDE_BAR_ROUTES.COMMODITY_VOLUMES.path.replace(
            ':productSlug',
            userStore.selectedProduct?.abbr
              ? userStore.selectedProduct?.abbr.toLocaleLowerCase()
              : '',
          )
        "
        :data-cy="`${cypressDataAttribute}ViewAll`"
      >
        {{ $t('base.clientsList.viewAll') }}
      </ClickButton>
    </div>
    <p
      v-if="isDashboard && clientCompanies?.clientCompanies?.collection?.length"
      class="client-list__helper"
    >
      {{ getComponentTerms('helperText') }}
    </p>
    <BaseSearch
      v-if="clientCompanies?.clientCompanies?.collection?.length || searchValue"
      v-model="searchValue"
      :data-cy="`${cypressDataAttribute}Search`"
      class="client-list__search"
    />
    <transition name="fade">
      <div v-if="loading" class="client-list__loader">
        <BaseSkeletonLoader :primary-hover-color="!inCard" />
      </div>
      <ul
        v-else-if="filteredClients.length"
        ref="clientListListRef"
        :data-cy="`${cypressDataAttribute}List`"
        class="client-list__list nice-scroll"
      >
        <li
          v-for="(item, index) in filteredClients"
          :key="index"
          :class="['client-list__item', { active: selectedCompany?.id === item.id }]"
          @click.prevent="onClientSelect(item)"
        >
          {{ item.legalName }}
          <BaseLoader v-if="item.id === loadingId" class="icon--medium" />
        </li>
        <li v-if="scrollLoading" class="client-list__item client-list__item_loader">
          <BaseSkeletonLoader :primary-hover-color="!inCard" />
        </li>
      </ul>
    </transition>
  </div>
  <div
    v-if="!clientCompanies?.clientCompanies?.collection?.length && !loading"
    class="commodity-clients__no-results"
    :class="{ 'change-style': !inCard }"
  >
    <p>
      {{ getComponentTerms('noClients') }}
    </p>
    <p v-if="inCard">
      {{ getComponentTerms('noClientsResults') }}
    </p>
  </div>
</template>

<style lang="scss" scoped>
.icon--medium {
  position: relative;
  height: 0px;
  :deep(svg) {
    width: auto;
    height: auto;
  }
}
.client-list {
  --text-color: var(--color-light);
  --scrollbar-color-local: var(--color-menu-active);

  display: flex;
  flex-direction: column;
  max-height: 100%;
  overflow: hidden;
  width: 100%;
  color: var(--text-color);

  &--in-card {
    --text-color: var(--color-font);
    --scrollbar-color-local: var(--color-primary-active);
  }

  &__loader {
    margin: remCalc(12) 0 0;
    display: flex;
    flex-direction: column;
    gap: var(--space-3xs);
  }

  &__header {
    display: flex;
    justify-content: space-between;
  }

  &__title {
    @extend %font-modal-title;

    .client-list--in-card & {
      @extend %fw-700;
      font-style: normal;
      font-size: remCalc(22);
      line-height: remCalc(32);
      color: var(--color-primary);
      padding-right: remCalc(40);
    }
  }

  &__helper {
    color: var(--color-primary);
    @extend %fw-700;
    font-size: var(--text-base);
  }

  &__search {
    margin: remCalc(12) 0 0;
    flex: 0 0 auto;
    color: var(--text-color);
    max-width: none;
    :deep {
      .searchbar__icon {
        fill: var(--text-color);
      }
      .searchbar__input {
        @extend %font-label;
        color: var(--text-color);
        &::placeholder {
          @extend %font-label;
          color: rgba(#fff, 0.8);
        }
      }
    }
  }
  &__list {
    overflow: auto;
    flex: 1 1 auto;
    padding: var(--space-3xs) 0;
    max-height: calc(90vh - 10rem);

    &.nice-scroll {
      --scrollbar-color: var(--scrollbar-color-local);
    }
  }

  &__item {
    @extend %font-label;
    padding: remCalc(12) var(--space-3xs);
    transition:
      color 0.2s ease-in-out,
      background-color 0.2s ease-in-out;
    border-radius: var(--border-radius-small);
    cursor: pointer;
    display: flex;
    position: relative;
    justify-content: space-between;
    align-items: center;

    &:hover {
      background-color: var(--color-primary-hover);
    }

    .client-list--in-card &:hover {
      background: var(--color-shade-11);
      box-shadow: 0 4px 4px rgba(78, 23, 7, 0.3);
      border-radius: 2px;
    }

    &.active {
      background-color: var(--color-menu-active);
      color: var(--color-main);
    }

    &:not(:first-child) {
      margin-top: var(--space-3xs);
    }
  }

  &__no-results {
    margin-top: var(--space-xxs);
  }
}
.commodity-clients__no-results {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: var(--space-xs);

  p {
    @extend %fw-400;
    font-size: var(--text-base);
    line-height: var(--line-base);
    &:first-child {
      margin-bottom: var(--space-3xs);
    }
  }

  &.change-style {
    p {
      color: $white;
    }
  }
}
</style>
