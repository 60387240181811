import {
  RouteLocationRaw,
  RouteRecordRaw,
  RouteLocation,
  RouteLocationNormalized,
  NavigationGuardNext,
} from 'vue-router';
import Dashboard from '@/views/Dashboard.vue';
import { useUserStore } from '@/store';
import { MAIN_ROUTES, COMMON_SIDE_BAR_ROUTES, USER_ROLE_ROUTES } from '@/config/constants/routes';
import { MODULES_LIST } from '@/config/constants/modules';
import { PRODUCTS_ABBR_LIST } from '@/config/constants/products';
import { ViewType } from '@/types/user';

const productSwitcherGuard = async (
  to: RouteLocation,
  from: RouteLocation,
  next: NavigationGuardNext,
) => {
  const { productSwitcher } = await import('@/utils/routeAndlogin');
  const result = await productSwitcher(to);
  if (result === true) {
    next();
  } else {
    next(result as RouteLocationRaw);
  }
};

const checkModuleAvailability = (moduleName: string): RouteLocationRaw | boolean => {
  const userStore = useUserStore();
  if (userStore.isClientRole) {
    if (!userStore.selectedProduct?.allowedModules?.includes(moduleName)) {
      return { name: MAIN_ROUTES.NOT_FOUND.name };
    }
  }
  return true;
};

const checkManagementAvailability = (): RouteLocationRaw | boolean => {
  const userStore = useUserStore();
  if (userStore.isClientRole) {
    return { name: MAIN_ROUTES.NOT_FOUND.name };
  }
  return true;
};

const checkOnlyBS = (): RouteLocationRaw | boolean => {
  const userStore = useUserStore();
  if (!userStore.isBSuppRole) {
    return { name: MAIN_ROUTES.NOT_FOUND.name };
  }
  return true;
};

const checkOnlyCR = (): RouteLocationRaw | boolean => {
  const userStore = useUserStore();
  if (!userStore.isClientRole) {
    return { name: MAIN_ROUTES.NOT_FOUND.name };
  }
  return true;
};

const loadComponentBasedOnRole = async () => {
  const userStore = useUserStore();
  if (userStore.isProductManagerRole || userStore.isBSuppRole) {
    return import('../views/energyExchangeModule/energyExchange/EeManagementPage.vue');
  }
  if (userStore.isRegularSARole) {
    return import('../views/energyExchangeModule/energyExchange/EeTradePage.vue');
  }
  return import('../views/404.vue');
};

// This function refresh the URL-Component mapping after role change (SA > MSA) without a full page refresh.
const dynamicComponentLoader = async (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext,
) => {
  const component = await loadComponentBasedOnRole();

  const matchedRoute = to.matched[0];
  if (matchedRoute) {
    matchedRoute.components = {
      ...matchedRoute.components,
      default: component.default || component,
    };
  }
  next();
};

const checkProductSlug = (to: RouteLocation): RouteLocationRaw | boolean => {
  const userStore = useUserStore();
  if (
    to.params?.productSlug &&
    !Object.values(PRODUCTS_ABBR_LIST).includes((to.params.productSlug as string).toUpperCase())
  ) {
    return { name: MAIN_ROUTES.NOT_FOUND.name };
  }
  // If user is BSUPP a product needs to be selected to go to these pages
  if (userStore.isBSuppRole && !userStore.getSelectedProduct) {
    return { name: MAIN_ROUTES.NOT_FOUND.name };
  }
  return true;
};

const removeClientView = (): RouteLocationRaw | boolean => {
  const userStore = useUserStore();
  if (userStore.getClientCompanyId && userStore.viewType === ViewType.CLIENT) {
    userStore.setViewType(ViewType.PERSONAL);
    userStore.setViewCompany(null);
  }
  return true;
};

const routes: Array<RouteRecordRaw> = [
  {
    path: '',
    name: MAIN_ROUTES.DASHBOARD.name,
    component: Dashboard,
    meta: {
      title: MAIN_ROUTES.DASHBOARD.title,
      requiresAuth: true,
    },
    redirect: {
      name: 'dashboardLayout',
    },
    children: [
      {
        path: MAIN_ROUTES.DASHBOARD.path,
        name: 'dashboardLayout',
        component: () => import('@/views/DashboardLayout.vue'),
      },
      {
        path: `${MAIN_ROUTES.DASHBOARD.path}/:productSlug`,
        name: 'productModule',
        props: true,
        component: () => import('@/views/ProductModule.vue'),
        redirect: MAIN_ROUTES.NOT_FOUND.name,
        beforeEnter: [productSwitcherGuard],
        children: [
          {
            path: COMMON_SIDE_BAR_ROUTES.CREATE_NEWSLETTER.path,
            name: COMMON_SIDE_BAR_ROUTES.CREATE_NEWSLETTER.name,
            props: true,
            component: () => import('@/views/blogModule/CreateNewsletterPost.vue'),
            meta: {
              title: COMMON_SIDE_BAR_ROUTES.CREATE_NEWSLETTER.title,
            },
            beforeEnter: [checkModuleAvailability.bind(null, MODULES_LIST.blog)],
          },
          {
            path: COMMON_SIDE_BAR_ROUTES.NEWSLETTER.path,
            name: COMMON_SIDE_BAR_ROUTES.NEWSLETTER.name,
            props: true,
            component: () => import('@/views/NewsletterModule.vue'),
            meta: {
              title: COMMON_SIDE_BAR_ROUTES.NEWSLETTER.title,
            },
            beforeEnter: [checkModuleAvailability.bind(null, MODULES_LIST.blog), checkProductSlug],
          },
          {
            path: COMMON_SIDE_BAR_ROUTES.VIEW_NEWSLETTER.path,
            name: COMMON_SIDE_BAR_ROUTES.VIEW_NEWSLETTER.name,
            props: true,
            component: () => import('@/views/newsletterModule/ViewNewsletter.vue'),
            meta: {
              title: COMMON_SIDE_BAR_ROUTES.VIEW_NEWSLETTER.title,
            },
            beforeEnter: [checkModuleAvailability.bind(null, MODULES_LIST.blog), checkProductSlug],
          },
          {
            path: COMMON_SIDE_BAR_ROUTES.EDIT_NEWSLETTER.path,
            name: COMMON_SIDE_BAR_ROUTES.EDIT_NEWSLETTER.name,
            props: true,
            component: () => import('@/views/newsletterModule/EditNewsletter.vue'),
            meta: {
              title: COMMON_SIDE_BAR_ROUTES.EDIT_NEWSLETTER.title,
            },
            beforeEnter: [
              checkModuleAvailability.bind(null, MODULES_LIST.blog),
              checkProductSlug,
              checkManagementAvailability,
            ],
          },
          {
            path: COMMON_SIDE_BAR_ROUTES.DOCUMENT_LIB.path,
            name: COMMON_SIDE_BAR_ROUTES.DOCUMENT_LIB.name,
            component: () => import('@/views/DocumentLibModule.vue'),
            meta: {
              title: COMMON_SIDE_BAR_ROUTES.DOCUMENT_LIB.title,
            },
            beforeEnter: [
              checkModuleAvailability.bind(null, MODULES_LIST.docLib),
              checkProductSlug,
            ],
          },
          {
            path: COMMON_SIDE_BAR_ROUTES.RECYCLE_BIN.path,
            name: COMMON_SIDE_BAR_ROUTES.RECYCLE_BIN.name,
            props: true,
            component: () => import('@/components/documents/RecycleBin.vue'),
            meta: {
              title: COMMON_SIDE_BAR_ROUTES.RECYCLE_BIN.title,
            },
            beforeEnter: [
              checkModuleAvailability.bind(null, MODULES_LIST.docLib),
              checkProductSlug,
            ],
          },
          {
            path: COMMON_SIDE_BAR_ROUTES.DOCUMENT_LIB_CATEGORIES.path,
            name: COMMON_SIDE_BAR_ROUTES.DOCUMENT_LIB_CATEGORIES.name,
            props: true,
            component: () => import('@/views/DocumentLibCategories.vue'),
            meta: {
              title: COMMON_SIDE_BAR_ROUTES.DOCUMENT_LIB_CATEGORIES.title,
            },
            beforeEnter: [
              checkModuleAvailability.bind(null, MODULES_LIST.docLib),
              checkProductSlug,
            ],
          },
          {
            path: COMMON_SIDE_BAR_ROUTES.TRADE_CATEGORIES.path,
            name: COMMON_SIDE_BAR_ROUTES.TRADE_CATEGORIES.name,
            props: true,
            component: () =>
              import(
                /* webpackChunkName: "TRADE_CATEGORIES" */ '@/views/DocumentLibCategories.vue'
              ),
            meta: {
              title: COMMON_SIDE_BAR_ROUTES.TRADE_CATEGORIES.title,
            },
            beforeEnter: [
              checkModuleAvailability.bind(null, MODULES_LIST.clientDoc),
              checkProductSlug,
            ],
          },
          {
            path: COMMON_SIDE_BAR_ROUTES.PRICES.path,
            name: COMMON_SIDE_BAR_ROUTES.PRICES.name,
            component: () => import('@/views/pricesModule/PricesModule.vue'),
            meta: {
              title: COMMON_SIDE_BAR_ROUTES.PRICES.title,
              requiresAuth: true,
            },
            beforeEnter: [checkModuleAvailability.bind(null, MODULES_LIST.price), checkProductSlug],
          },
          {
            path: COMMON_SIDE_BAR_ROUTES.TRANSACTIONS.path,
            name: COMMON_SIDE_BAR_ROUTES.TRANSACTIONS.name,
            props: true,
            component: () => import('@/views/transactions/Transactions.vue'),
            meta: {
              title: COMMON_SIDE_BAR_ROUTES.TRANSACTIONS.title,
              requiresAuth: true,
            },
            beforeEnter: [
              checkModuleAvailability.bind(null, MODULES_LIST.transactions),
              checkProductSlug,
            ],
          },
          {
            path: COMMON_SIDE_BAR_ROUTES.TRANSACTION_DETAILS.path,
            name: COMMON_SIDE_BAR_ROUTES.TRANSACTION_DETAILS.name,
            props: true,
            component: () => import('@/views/transactions/TransactionDetails.vue'),
            meta: {
              title: COMMON_SIDE_BAR_ROUTES.TRANSACTION_DETAILS.title,
              requiresAuth: true,
            },
            beforeEnter: [
              checkModuleAvailability.bind(null, MODULES_LIST.transactions),
              checkProductSlug,
            ],
          },
          {
            path: COMMON_SIDE_BAR_ROUTES.COMMODITY_VOLUMES.path,
            name: COMMON_SIDE_BAR_ROUTES.COMMODITY_VOLUMES.name,
            props: true,
            component: () => import('@/views/commodityVolumes/CommodityVolumes.vue'),
            meta: {
              title: COMMON_SIDE_BAR_ROUTES.COMMODITY_VOLUMES.title,
              requiresAuth: true,
            },
            beforeEnter: [
              checkModuleAvailability.bind(null, MODULES_LIST.commodityVolumes),
              checkProductSlug,
            ],
          },
        ],
      },
    ],
  },
  {
    path: COMMON_SIDE_BAR_ROUTES.BLOG.path,
    name: COMMON_SIDE_BAR_ROUTES.BLOG.name,
    props: true,
    component: () => import('@/views/BlogModule.vue'),
    meta: {
      title: COMMON_SIDE_BAR_ROUTES.BLOG.title,
    },
    beforeEnter: [checkModuleAvailability.bind(null, MODULES_LIST.blog), checkProductSlug],
  },
  {
    path: COMMON_SIDE_BAR_ROUTES.VIEW_BLOG_POST.path,
    name: COMMON_SIDE_BAR_ROUTES.VIEW_BLOG_POST.name,
    props: true,
    component: () => import('@/views/blogModule/ViewBlogPost.vue'),
    meta: {
      title: COMMON_SIDE_BAR_ROUTES.VIEW_BLOG_POST.title,
    },
    beforeEnter: [checkModuleAvailability.bind(null, MODULES_LIST.blog), checkProductSlug],
  },
  {
    path: COMMON_SIDE_BAR_ROUTES.WEBINARS.path,
    name: COMMON_SIDE_BAR_ROUTES.WEBINARS.name,
    props: true,
    component: () => import('@/views/webinarsModule/WebinarsList.vue'),
    meta: {
      title: COMMON_SIDE_BAR_ROUTES.WEBINARS.title,
      requiresAuth: true,
    },
    beforeEnter: [checkModuleAvailability.bind(null, MODULES_LIST.webinars), checkProductSlug],
  },
  {
    path: COMMON_SIDE_BAR_ROUTES.VIEW_WEBINAR.path,
    name: COMMON_SIDE_BAR_ROUTES.VIEW_WEBINAR.name,
    props: true,
    component: () => import('@/views/webinarsModule/ViewWebinar.vue'),
    meta: {
      title: COMMON_SIDE_BAR_ROUTES.VIEW_WEBINAR.title,
      requiresAuth: true,
    },
    beforeEnter: [checkModuleAvailability.bind(null, MODULES_LIST.webinars), checkProductSlug],
  },
  {
    path: USER_ROLE_ROUTES.BUSINESS_SUPPORTS.path,
    name: USER_ROLE_ROUTES.BUSINESS_SUPPORTS.name,
    component: () => import('@/views/BusinessSupport.vue'),
    meta: {
      title: USER_ROLE_ROUTES.BUSINESS_SUPPORTS.title,
      requiresAuth: true,
    },
    beforeEnter: [checkOnlyBS],
  },
  {
    path: COMMON_SIDE_BAR_ROUTES.CLIENT_DOCUMENTS.path,
    name: COMMON_SIDE_BAR_ROUTES.CLIENT_DOCUMENTS.name,
    component: () => import('@/views/DocumentLibModule.vue'),
    meta: {
      title: COMMON_SIDE_BAR_ROUTES.CLIENT_DOCUMENTS.title,
      requiresAuth: true,
    },
    beforeEnter: [checkModuleAvailability.bind(null, MODULES_LIST.clientDoc), checkProductSlug],
  },
  {
    path: COMMON_SIDE_BAR_ROUTES.MY_DOCUMENTS.path,
    name: COMMON_SIDE_BAR_ROUTES.MY_DOCUMENTS.name,
    component: () => import('@/views/DocumentLibModule.vue'),
    meta: {
      title: COMMON_SIDE_BAR_ROUTES.MY_DOCUMENTS.title,
      requiresAuth: true,
    },
    beforeEnter: [checkModuleAvailability.bind(null, MODULES_LIST.clientDoc), checkProductSlug],
  },
  {
    path: USER_ROLE_ROUTES.BUSINESS_SUPPORT_PROFILE.path,
    name: USER_ROLE_ROUTES.BUSINESS_SUPPORT_PROFILE.name,
    props: true,
    component: () => import('@/views/BusinessSupportProfile.vue'),
    meta: {
      title: USER_ROLE_ROUTES.BUSINESS_SUPPORT_PROFILE.title,
      requiresAuth: false,
    },
  },
  {
    path: MAIN_ROUTES.AUTH.path,
    name: MAIN_ROUTES.AUTH.name,
    component: () => import('@/views/Auth.vue'),
    meta: {
      title: MAIN_ROUTES.AUTH.title,
      requiresAuth: false,
    },
  },
  {
    path: MAIN_ROUTES.PRODUCT_SWITCHER.path,
    name: MAIN_ROUTES.PRODUCT_SWITCHER.name,
    component: () => import('@/views/ProductSwitcher.vue'),
    meta: {
      title: MAIN_ROUTES.PRODUCT_SWITCHER.title,
      requiresAuth: true,
    },
  },
  {
    path: MAIN_ROUTES.RESET_PASSWORD.path,
    name: MAIN_ROUTES.RESET_PASSWORD.name,
    component: () => import('@/views/ResetPassword.vue'),
    meta: {
      title: MAIN_ROUTES.RESET_PASSWORD.title,
      requiresAuth: false,
    },
  },
  {
    path: MAIN_ROUTES.BUTTONS.path,
    name: MAIN_ROUTES.BUTTONS.name,
    component: () => import('@/views/Buttons.vue'),
    meta: {
      title: MAIN_ROUTES.BUTTONS.title,
      requiresAuth: false,
    },
  },
  {
    path: MAIN_ROUTES.CHAT.path,
    name: MAIN_ROUTES.CHAT.name,
    component: () => import('@/views/chatModule/ChatModule.vue'),
    meta: {
      title: MAIN_ROUTES.CHAT.title,
      requiresAuth: true,
    },
    beforeEnter: [checkManagementAvailability],
  },
  {
    path: MAIN_ROUTES.SETTINGS.path,
    name: MAIN_ROUTES.SETTINGS.name,
    component: () => import('@/views/settingsModule/SettingsModule.vue'),
    meta: {
      title: MAIN_ROUTES.SETTINGS.title,
      requiresAuth: true,
    },
  },
  {
    path: MAIN_ROUTES.NOTIFICATIONS.path,
    name: MAIN_ROUTES.NOTIFICATIONS.name,
    component: () => import('@/views/notificationsModule/NotificationsModule.vue'),
    meta: {
      title: MAIN_ROUTES.NOTIFICATIONS.title,
      requiresAuth: true,
    },
    beforeEnter: [removeClientView],
  },
  {
    path: MAIN_ROUTES.CALENDAR.path,
    name: MAIN_ROUTES.CALENDAR.name,
    component: () => import('@/views/calendarModule/CalendarModule.vue'),
    meta: {
      title: MAIN_ROUTES.CALENDAR.title,
      requiresAuth: true,
    },
    beforeEnter: [checkModuleAvailability.bind(null, MODULES_LIST.calendar)],
  },
  {
    path: MAIN_ROUTES.RFQ.path,
    name: MAIN_ROUTES.RFQ.name,
    component: () => import('@/views/rfqModule/RfqModule.vue'),
    props: true,
    meta: {
      title: MAIN_ROUTES.RFQ.title,
      requiresAuth: true,
    },
    beforeEnter: [productSwitcherGuard, checkModuleAvailability.bind(null, MODULES_LIST.rfq)],
  },
  {
    path: MAIN_ROUTES.RFQ_THANKS.path,
    name: MAIN_ROUTES.RFQ_THANKS.name,
    component: () => import('@/views/rfqModule/RfqThankYouPage.vue'),
    meta: {
      title: MAIN_ROUTES.RFQ_THANKS.title,
      requiresAuth: true,
    },
    beforeEnter: [checkModuleAvailability.bind(null, MODULES_LIST.rfq)],
  },
  {
    path: MAIN_ROUTES.RFQ_CANCELLED.path,
    name: MAIN_ROUTES.RFQ_CANCELLED.name,
    component: () => import('@/views/rfqModule/RfqCancelledPage.vue'),
    meta: {
      title: MAIN_ROUTES.RFQ_CANCELLED.title,
      requiresAuth: true,
    },
    beforeEnter: [checkModuleAvailability.bind(null, MODULES_LIST.rfq)],
  },
  {
    path: MAIN_ROUTES.RFQ_HISTORY.path,
    name: MAIN_ROUTES.RFQ_HISTORY.name,
    props: true,
    component: () => import('@/views/rfqModule/RfqHistoryPage.vue'),
    meta: {
      title: MAIN_ROUTES.RFQ_HISTORY.title,
      requiresAuth: true,
    },
    beforeEnter: [checkModuleAvailability.bind(null, MODULES_LIST.rfq)],
  },
  {
    path: MAIN_ROUTES.RFQ_DETAILS.path,
    name: MAIN_ROUTES.RFQ_DETAILS.name,
    props: true,
    component: () => import('@/views/rfqModule/RfqDetailsPage.vue'),
    meta: {
      title: MAIN_ROUTES.RFQ_DETAILS.title,
      requiresAuth: true,
    },
    beforeEnter: [productSwitcherGuard, checkModuleAvailability.bind(null, MODULES_LIST.rfq)],
  },
  {
    path: '/clients/profile/:userId',
    name: USER_ROLE_ROUTES.CLIENT_PROFILE.name,
    props: true,
    component: () => import('@/views/clientsModule/ClientsProfile.vue'),
    meta: {
      title: USER_ROLE_ROUTES.CLIENT_PROFILE.title,
      requiresAuth: true,
    },
  },
  {
    path: USER_ROLE_ROUTES.CLIENT_NEW.path,
    name: USER_ROLE_ROUTES.CLIENT_NEW.name,
    props: true,
    component: () => import('@/views/clientsModule/ClientsNew.vue'),
    meta: {
      title: USER_ROLE_ROUTES.CLIENT_NEW.title,
      requiresAuth: true,
    },
    beforeEnter: [checkManagementAvailability],
  },
  {
    path: USER_ROLE_ROUTES.APPROVE_CLIENT.path,
    name: USER_ROLE_ROUTES.APPROVE_CLIENT.name,
    props: true,
    component: () => import('@/views/clientsModule/ApproveClient.vue'),
    meta: {
      title: USER_ROLE_ROUTES.APPROVE_CLIENT.title,
      requiresAuth: true,
    },
    beforeEnter: [removeClientView, checkOnlyBS],
  },
  {
    path: USER_ROLE_ROUTES.CLIENTS_CREATE_SUBENTITY.path,
    name: USER_ROLE_ROUTES.CLIENTS_CREATE_SUBENTITY.name,
    props: true,
    component: () => import('@/views/companyModule/CreateSubEntity.vue'),
    meta: {
      title: USER_ROLE_ROUTES.CLIENTS_CREATE_SUBENTITY.title,
      requiresAuth: true,
    },
  },
  {
    path: `${USER_ROLE_ROUTES.CLIENTS_SUBENTITY_DETAILS.path}/:subEntityId`,
    name: USER_ROLE_ROUTES.CLIENTS_SUBENTITY_DETAILS.name,
    props: true,
    component: () => import('@/views/companyModule/SubEntityDetails.vue'),
    meta: {
      title: USER_ROLE_ROUTES.CLIENTS_SUBENTITY_DETAILS.title,
      requiresAuth: true,
    },
  },
  {
    path: USER_ROLE_ROUTES.CLIENTS.path,
    name: USER_ROLE_ROUTES.CLIENTS.name,
    component: () => import('@/views/Clients.vue'),
    meta: {
      title: USER_ROLE_ROUTES.CLIENTS.title,
      requiresAuth: true,
    },
    beforeEnter: [checkManagementAvailability],
  },
  {
    path: USER_ROLE_ROUTES.SUSTAINABILITY_ADVISORS.path,
    name: USER_ROLE_ROUTES.SUSTAINABILITY_ADVISORS.name,
    component: () => import('@/views/SustainabilityAdvisors.vue'),
    meta: {
      title: USER_ROLE_ROUTES.SUSTAINABILITY_ADVISORS.title,
      requiresAuth: true,
    },
    beforeEnter: [checkOnlyBS],
  },
  {
    path: USER_ROLE_ROUTES.SUSTAINABILITY_ADVISORS_NEW.path,
    name: USER_ROLE_ROUTES.SUSTAINABILITY_ADVISORS_NEW.name,
    component: () => import('@/views/SustainabilityAdvisorNew.vue'),
    meta: {
      title: USER_ROLE_ROUTES.SUSTAINABILITY_ADVISORS_NEW.title,
      requiresAuth: true,
    },
    beforeEnter: [checkOnlyBS],
  },
  {
    path: `${USER_ROLE_ROUTES.SUSTAINABILITY_ADVISOR_PROFILE.path}/:id`,
    name: USER_ROLE_ROUTES.SUSTAINABILITY_ADVISOR_PROFILE.name,
    component: () => import('@/views/SustainabilityAdvisorProfile.vue'),
    meta: {
      title: USER_ROLE_ROUTES.SUSTAINABILITY_ADVISOR_PROFILE.title,
      requiresAuth: true,
    },
  },
  {
    path: USER_ROLE_ROUTES.COMPANY.path,
    name: USER_ROLE_ROUTES.COMPANY.name,
    component: () => import('@/views/UserCompany.vue'),
    meta: {
      title: USER_ROLE_ROUTES.COMPANY.title,
      requiresAuth: true,
    },
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: MAIN_ROUTES.NOT_FOUND.name,
  },
  {
    path: `${USER_ROLE_ROUTES.CLIENT_COMPANY_PROFILE.path}/:id`,
    name: USER_ROLE_ROUTES.CLIENT_COMPANY_PROFILE.name,
    component: () => import('@/views/ClientCompanyProfile.vue'),
    meta: {
      title: USER_ROLE_ROUTES.CLIENT_COMPANY_PROFILE.title,
      requiresAuth: true,
    },
    beforeEnter: [checkManagementAvailability],
  },
  {
    path: MAIN_ROUTES.IOI_DETAIL.path,
    name: MAIN_ROUTES.IOI_DETAIL.name,
    props: true,
    component: () =>
      import('../views/energyExchangeModule/indicationOfInterest/indicationOfInterestDetail.vue'),
    meta: {
      title: MAIN_ROUTES.IOI_DETAIL.title,
      requiresAuth: true,
    },
    beforeEnter: [checkManagementAvailability, productSwitcherGuard],
  },
  {
    path: MAIN_ROUTES.IOI_OVERVIEW.path,
    name: MAIN_ROUTES.IOI_OVERVIEW.name,
    props: true,
    component: () =>
      import('../views/energyExchangeModule/indicationOfInterest/indicationOfInterestOverview.vue'),
    meta: {
      title: MAIN_ROUTES.IOI_OVERVIEW.title,
      requiresAuth: true,
    },
    beforeEnter: [checkManagementAvailability, productSwitcherGuard],
  },
  {
    path: MAIN_ROUTES.ENERGY_EXCHANGE_TRADE.path,
    name: MAIN_ROUTES.ENERGY_EXCHANGE_TRADE.name,
    props: true,
    component: () => import('../views/energyExchangeModule/energyExchange/EeTradePage.vue'),
    meta: {
      title: MAIN_ROUTES.ENERGY_EXCHANGE_TRADE.title,
      requiresAuth: true,
    },
    beforeEnter: [
      productSwitcherGuard,
      checkOnlyCR,
      checkModuleAvailability.bind(null, MODULES_LIST.energyExchange),
    ],
  },
  {
    path: MAIN_ROUTES.ENERGY_EXCHANGE_MANAGEMENT.path,
    name: MAIN_ROUTES.ENERGY_EXCHANGE_MANAGEMENT.name,
    props: true,
    component: () => loadComponentBasedOnRole(),
    meta: {
      title: MAIN_ROUTES.ENERGY_EXCHANGE_MANAGEMENT.title,
      requiresAuth: true,
    },
    beforeEnter: [productSwitcherGuard, checkManagementAvailability, dynamicComponentLoader],
  },
  {
    path: MAIN_ROUTES.ENERGY_EXCHANGE_ORDER_DETAIL.path,
    name: MAIN_ROUTES.ENERGY_EXCHANGE_ORDER_DETAIL.name,
    props: true,
    component: () => import('@/views/energyExchangeModule/energyExchange/EeOrderDetails.vue'),
    meta: {
      title: MAIN_ROUTES.ENERGY_EXCHANGE_ORDER_DETAIL.title,
    },
    beforeEnter: [
      checkModuleAvailability.bind(null, MODULES_LIST.energyExchange),
      checkProductSlug,
    ],
  },
  {
    path: MAIN_ROUTES.ENERGY_EXCHANGE_ORDER_DETAIL_MANAGEMENT.path,
    name: MAIN_ROUTES.ENERGY_EXCHANGE_ORDER_DETAIL_MANAGEMENT.name,
    props: true,
    component: () => import('@/views/energyExchangeModule/energyExchange/EeOrderDetails.vue'),
    meta: {
      title: MAIN_ROUTES.ENERGY_EXCHANGE_ORDER_DETAIL_MANAGEMENT.title,
    },
    beforeEnter: [
      checkModuleAvailability.bind(null, MODULES_LIST.energyExchange),
      checkProductSlug,
    ],
  },
  {
    path: MAIN_ROUTES.ENERGY_EXCHANGE_CLIENT.path,
    name: MAIN_ROUTES.ENERGY_EXCHANGE_CLIENT.name,
    props: true,
    component: () => import('../views/energyExchangeModule/energyExchange/EeClientPage.vue'),
    meta: {
      title: MAIN_ROUTES.ENERGY_EXCHANGE_CLIENT.title,
      requiresAuth: true,
    },
    beforeEnter: [checkOnlyCR],
  },
  {
    path: MAIN_ROUTES.INVITE.path,
    name: MAIN_ROUTES.INVITE.name,
    component: () => import('@/views/Invite.vue'),
    meta: {
      title: MAIN_ROUTES.INVITE.title,
    },
  },
  {
    path: MAIN_ROUTES.API_PROFILE.path,
    name: MAIN_ROUTES.API_PROFILE.name,
    component: () => import('@/views/ioi/APIProfile.vue'),
    meta: {
      title: MAIN_ROUTES.API_PROFILE.title,
    },
  },
  {
    path: MAIN_ROUTES.NOT_FOUND.path,
    name: MAIN_ROUTES.NOT_FOUND.name,
    component: () => import('../views/404.vue'),
    meta: {
      title: MAIN_ROUTES.NOT_FOUND.title,
    },
  },
  {
    path: MAIN_ROUTES.PORTAL_FEATURES.path,
    name: MAIN_ROUTES.PORTAL_FEATURES.name,
    component: () => import('@/components/onboarding/PortalFeatures.vue'),
    meta: {
      title: MAIN_ROUTES.PORTAL_FEATURES.title,
      requiresAuth: true,
    },
  },
  {
    path: MAIN_ROUTES.PRODUCT_DETAILS.path,
    name: MAIN_ROUTES.PRODUCT_DETAILS.name,
    component: () => import('@/components/products/ProductDetails.vue'),
    meta: {
      title: MAIN_ROUTES.PRODUCT_DETAILS.title,
      requiresAuth: true,
    },
  },
];

export default routes;
