import {
  InputMaybe,
  SustainabilityAdvisorProductInputType,
  SustainabilityAdvisorsProductInputType,
} from '../graphql';
import { ClientCompany, ClientCompanyClientView } from '@/types/navigation';

export enum UserRole {
  ROLE_ADMIN = 'admin',
  ROLE_BUSINESS_SUPPORT = 'business support',
  ROLE_SUSTAINABILITY_ADVISOR = 'sustainability advisor',
  ROLE_COMPANY_REPRESENTATIVE = 'company representative',
  ROLE_API_USER = 'api user',
}

export enum BackEndIdTypes {
  accounts = '/api/accounts/',
  people = '/api/people/',
}

export enum CompaniesDocumentTypes {
  PRODUCT_TYPE = 'product documents',
  CLIENT_TYPE = 'client document categories',
}

export enum ViewType {
  PERSONAL = 'personalView',
  CLIENT = 'clientView',
}

export interface LoginFormData {
  email: string;
  password: string;
}

export interface SelectedProductType {
  id: string;
  abbr: string;
  name: string;
  isProductManager?: boolean;
  allowedModules?: string[];
  available?: boolean;
  defaultDashboardGroup?: string;
}

export interface AppointedClientType {
  id: string;
  legalName: string;
}

export interface UserState {
  accessToken?: string | null;
  mercureToken?: string | null;
  squidexToken?: string;
  id?: string | null;
  clientCompanyId?: string | null;
  clientCompanyName?: string | null;
  createdAt?: string | null;
  firstName?: string;
  lastName?: string;
  middleName?: string;
  phoneNumber?: string;
  email?: string;
  roles?: string[] | null;
  is2FaInProgress?: boolean | null;
  selectedProduct?: SelectedProductType | null;
  appointedProducts?: SelectedProductType[];
  appointedClients?: AppointedClientType[];
  viewType?: string | null | undefined;
  selectedTab?: string | null | undefined;
  viewCompany?: ClientCompanyClientView | null | undefined;
  clientCompany?: ClientCompany | null | undefined;
  copywriterRole?: boolean | null | undefined;
  avatar: string | null | undefined;
  savedUrl: {
    pathname: string;
    search: string;
    hash: string;
  };
  componentTabs: {
    [componentId: string]: string;
  };
}

export interface BaseProfileForm {
  firstName: string;
  lastName: string;
  middleName: string;
  phoneNumber: string;
  email: string;
  bio?: string | undefined;
  calendarId?: string;
  calendlyId?: string;
  products?: string[];
  selectedProductsIds?: { id: string };
  isCopywriter?: boolean;
  timezone?: string | null;
  appointedClientCompanies: InputMaybe<Array<InputMaybe<SustainabilityAdvisorsProductInputType>>>;
}

export interface BaseProfileSubmitData {
  form: BaseProfileForm;
  selectedProductsIds?: SustainabilityAdvisorProductInputType[];
  selectedClientProductsIds?: string[];
}

export interface AccountQueryInput {
  page?: number;
  search?: string;
  saProduct?: string | null;
  crProduct?: string | null;
  status?: string;
  type?: string;
}
export interface TabItem {
  title: string;
  value: string;
  disabled?: boolean;
}
export type SortField = {
  name: string;
  type: string;
};
