import { gql } from '@apollo/client/core';
import { CHAT_MESSAGE_FIELDS } from './fragments';

export const GET_CHATS_ROOMS = gql`
  query chatRoom(
    $participants_firstName: String
    $participants_lastName: String
    $sentAtOrder: String = "DESC"
    $search: String
    $page: Int
    $clientCompany_primarySubEntity_legalName: String
    $participants_type: String
  ) @api(name: "authEndPoint") {
    chatRooms(
      participants_firstName: $participants_firstName
      participants_lastName: $participants_lastName
      order: { messages_sentAt: $sentAtOrder }
      search: $search
      page: $page
      clientCompany_primarySubEntity_legalName: $clientCompany_primarySubEntity_legalName
      participants_type: $participants_type
    ) {
      paginationInfo {
        totalCount
        lastPage
        itemsPerPage
      }
      collection {
        id
        name
        unreadCount
        lastMessageContent
        lastMessageTime
        clientCompany {
          id
          legalName
        }
        product {
          id
          abbreviation
          name
          sustainabilityAdvisors {
            edges {
              node {
                id
                isProductManager
                account {
                  id
                }
                product {
                  id
                  name
                }
              }
            }
          }
        }
        participants(itemsPerPage: 200) {
          collection {
            avatar {
              id
              contentUrl
              fileName
              filePath
            }
            id
            firstName
            lastName
            middleName
            email
            roles
            clientCompany {
              id
              legalName
            }
          }
        }
      }
    }
  }
`;

export const GET_SPECIFIC_ROOM = gql`
  query getSpecificRoom($id: ID!) @api(name: "authEndPoint") {
    chatRoom(id: $id) {
      id
      name
      unreadCount
      lastMessageContent
      lastMessageTime
      clientCompany {
        id
        legalName
      }
      product {
        id
        abbreviation
        name
        sustainabilityAdvisors {
          edges {
            node {
              id
              isProductManager
              account {
                id
              }
              product {
                id
                name
              }
            }
          }
        }
      }
      participants(itemsPerPage: 200) {
        collection {
          avatar {
            id
            contentUrl
            fileName
            filePath
          }
          id
          firstName
          lastName
          middleName
          email
          roles
          clientCompany {
            id
            legalName
          }
        }
      }
    }
  }
`;

export const GET_CHATS_MESSAGES = gql`
  ${CHAT_MESSAGE_FIELDS}
  query chatMessages($chatRoom: String, $page: Int, $itemsPerPage: Int) @api(name: "authEndPoint") {
    chatMessages(chatRoom: $chatRoom, page: $page, itemsPerPage: $itemsPerPage) {
      paginationInfo {
        itemsPerPage
        lastPage
        totalCount
      }
      collection {
        ...chatMessageFields
      }
    }
  }
`;

export const CREATE_CHAT_MESSAGE = gql`
  ${CHAT_MESSAGE_FIELDS}
  mutation createChatMessage($input: createChatMessageInput!) @api(name: "authEndPoint") {
    createChatMessage(input: $input) {
      chatMessage {
        ...chatMessageFields
      }
    }
  }
`;

export const JOIN_CHAT = gql`
  mutation joinChatRoom($input: joinChatRoomInput!) @api(name: "authEndPoint") {
    joinChatRoom(input: $input) {
      chatRoom {
        id
        name
      }
    }
  }
`;

export const GET_USERS_CHAT = gql`
  query getUsersChat(
    $status: String
    $type: String
    $search: String
    $sustainabilityAdvisorProducts_product_id: String
    $orderByFullName: String
    $page: Int
    $itemsPerPage: Int
  ) @api(name: "authEndPoint") {
    accounts(
      status: $status
      type: $type
      page: $page
      itemsPerPage: $itemsPerPage
      search: $search
      sustainabilityAdvisorProducts_product_id: $sustainabilityAdvisorProducts_product_id
      orderByFullName: $orderByFullName
    ) {
      paginationInfo {
        itemsPerPage
        lastPage
        totalCount
      }
      collection {
        id
        firstName
        lastName
        middleName
      }
    }
  }
`;

export const GET_CLIENT_DOCUMENT_CONTENT_URL = gql`
  query GetClientDocumentContentUrl($id: ID!) @api(name: "authEndPoint") {
    clientCompanyDocument(id: $id) {
      id
      title
      note
      contentUrl
    }
  }
`;

export const CREATE_TRADE_CHAT_ROOM = gql`
  mutation createTradeChatRoom($input: createTradeChatRoomInput!) @api(name: "authEndPoint") {
    createTradeChatRoom(input: $input) {
      chatRoom {
        id
      }
    }
  }
`;

export const GET_CHAT_ID_AND_PARTICIPANTS = gql`
  query getChatIdAndParticipants($sentAtOrder: String = "DESC", $search: String, $page: Int)
  @api(name: "authEndPoint") {
    chatRooms(order: { messages_sentAt: $sentAtOrder }, search: $search, page: $page) {
      paginationInfo {
        totalCount
        lastPage
        itemsPerPage
      }
      collection {
        id
        participants(itemsPerPage: 200) {
          collection {
            avatar {
              id
              contentUrl
              fileName
              filePath
            }
            id
            firstName
            lastName
            middleName
            email
            roles
            clientCompany {
              id
              legalName
            }
          }
        }
      }
    }
  }
`;
