<script lang="ts">
import { computed, defineComponent, onMounted, ref, watch, onUnmounted, watchEffect } from 'vue';
import FetchEventSource from 'fetch-event-source';
import { useRoute, useRouter } from 'vue-router';
import { storeToRefs } from 'pinia';
import { log } from 'debug';
import dayjs from 'dayjs';
import { jwtDecode } from 'jwt-decode';
import {
  useGlobalStore,
  useUserStore,
  useMessageStore,
  useNotificationStore,
  usePushNotificationsStore,
} from '@/store';
import { MAIN_ROUTES } from '@/config/constants/routes';
import { getClassByRole } from '@/utils/composable/globalHelpers';
import TopNavBar from '@/components/navigation/TopNavBar.vue';
import Notification from '@/components/notification/Notification.vue';
import { NotificationType } from '@/types/notification';
import ChatModule from '@/views/chatModule/ChatModule.vue';
import { UserRole, ViewType } from './types/user';
import BaseModal from '@/components/base/BaseModal.vue';
import ProductSwitcher from '@/views/ProductSwitcher.vue';
import { getTranslationTerms } from '@/utils/composable/localeHelper';
import { formatChatTimestamp, getFormattedDate } from '@/utils/composable/date';
import {
  getFormattedNotificationItem,
  isAllowedNotificationType,
} from './utils/composable/notifications';
import {
  GetClientDocumentContentUrlQuery,
  useGetClientDocumentContentUrlLazyQuery,
} from '@/types/graphql/chat';
import messageSound from '@/assets/audio/messageSound.mp3';
import bullSound from '@/assets/audio/bull.mp3';
import bellSound from '@/assets/audio/bell.mp3';
import bearSound from '@/assets/audio/bear.mp3';
import dogCancelledSound from '@/assets/audio/dog-cancelled.mp3';
import elephantSound from '@/assets/audio/elephant-sound.mp3';
import { TransformedMessage, MercureFile } from '@/types/base-types';
import TopClientViewBar from '@/components/navigation/TopClientViewBar.vue';
import IconNavBar from '@/components/navigation/IconNavBar.vue';
import {
  isIconBarOpen,
  isMobileMenuOpen,
  isMobileWindowWidth,
  updateWidth,
} from '@/utils/composable/navigation';
import { indicationOfInterestNotificationHandler } from '@/utils/indicationOfInterest';
import { handleOrderViaWebsocket, handleExchangeStatus } from '@/utils/energyExchange';
import { trackInteraction } from '@/utils/composable/InteractionNeeded';
import { fetchSquidexToken } from '@/services/apollo';
import { provideRollInputsUpdated } from '@/utils/composable/EnergyExchange';
import { ChatMessageFieldsFragment } from '@/types/graphql';

export default defineComponent({
  name: 'App',
  components: {
    IconNavBar,
    TopClientViewBar,
    TopNavBar,
    Notification,
    ChatModule,
    BaseModal,
    ProductSwitcher,
  },
  setup() {
    const eventSource = ref<EventSource | FetchEventSource | null>(null);
    const getPageTerms = getTranslationTerms.bind(null, 'pages', 'productSwitcher');
    const getStateMsgTerms = getTranslationTerms.bind(null, 'base', 'errorMessages');
    const userStore = useUserStore();
    const { saveUrl } = userStore;
    const { getSelectedProduct } = storeToRefs(userStore);
    const pushNotificationStore = usePushNotificationsStore();
    const { toggleProductSwitcherPopup } = useGlobalStore();
    const globalStore = useGlobalStore();
    const { toggleChat } = globalStore;
    const route = useRoute();
    const { push } = useRouter();
    const { addNotification } = useNotificationStore();
    const {
      addMessageTransformed,
      addMessageOriginal,
      addUnreadMessagesCount,
      setSelectedIdNotification,
    } = useMessageStore();
    const userNotificationStore = useNotificationStore();
    const messageSoundRef = ref<HTMLAudioElement | null>(null);
    const bullSoundRef = ref<HTMLAudioElement | null>(null);
    const bellSoundRef = ref<HTMLAudioElement | null>(null);
    const bearSoundRef = ref<HTMLAudioElement | null>(null);
    const dogCancelledSoundRef = ref<HTMLAudioElement | null>(null);
    const elephantSoundRef = ref<HTMLAudioElement | null>(null);

    const dashboardRoute = computed(() => route.path === MAIN_ROUTES.DASHBOARD.path);
    const chatModuleRoute = computed(() => route.path === MAIN_ROUTES?.CHAT?.path);
    const isBSuppRole = computed(() => userStore.getUserRole === UserRole.ROLE_BUSINESS_SUPPORT);
    const isLoggedIn = computed(
      () =>
        userStore.isUserLoggedIn &&
        route.path !== MAIN_ROUTES.INVITE.path &&
        route.path !== MAIN_ROUTES.PRODUCT_SWITCHER.path &&
        route.name !== MAIN_ROUTES.PRODUCT_DETAILS.name &&
        Boolean(isBSuppRole.value || getSelectedProduct.value),
    );
    const userRole = computed(() => userStore.getUserRole);

    const { logout } = useUserStore();

    const roleClassName = () => getClassByRole(userStore.getUserRole as UserRole);
    const selectedChatId = useMessageStore();
    const lengthChatRooms = ref<number>(0);
    const chatRoomId = computed(() => selectedChatId.getSelectedIdNotification);
    const clientCompanyDocument = ref<GetClientDocumentContentUrlQuery['clientCompanyDocument']>();
    const selectedChatRoomId = ref<string>('');
    const getSelectedIdNotification = ref<string>('');
    const mercureFile = ref<MercureFile[]>([]);
    const refetchFunctions = provideRollInputsUpdated();

    const isTransactionChatPage = computed(() => {
      const isBiofuelsTransactionPath = route.path.includes('/dashboard/biofuels/transactions/');
      const hasChatHash = route.hash === '#chat';
      return isBiofuelsTransactionPath && hasChatHash;
    });

    const getClientDocumentContentUrl = async (id: string) => {
      mercureFile.value = [];
      const { load, onResult } = useGetClientDocumentContentUrlLazyQuery({ id });

      onResult((result) => {
        if (!result.loading) {
          clientCompanyDocument.value = result.data.clientCompanyDocument;
          const fileName = clientCompanyDocument.value?.title;
          let fileTitle = '';
          let fileExtension = '';
          let fileType = '';
          if (fileName) {
            const lastDotIndex = fileName.lastIndexOf('.');
            if (lastDotIndex !== -1) {
              fileTitle = fileName.substring(0, lastDotIndex) as string;
              fileExtension = fileName.substring(lastDotIndex + 1);
              fileType =
                fileExtension === 'svg' ||
                fileExtension === 'png' ||
                fileExtension === 'jpeg' ||
                fileExtension === 'jpg'
                  ? `image/${fileExtension}`
                  : `application/${fileExtension}`;
            }
          }
          mercureFile.value.push({
            name: fileTitle ?? '',
            type: fileType,
            extension: fileExtension,
            url: clientCompanyDocument.value?.contentUrl ?? '',
          });
        }
      });

      load();
    };

    const logoutListener = async (event: StorageEvent) => {
      if (event.key === 'user' && !event.newValue) {
        addNotification({
          message: getStateMsgTerms('expiredTokenHeader'),
          description: getStateMsgTerms('expiredToken'),
          type: NotificationType.ALERT,
          showIcon: true,
        });
        await logout();
        await push({ name: MAIN_ROUTES.AUTH.name });
      }
    };

    const onOpenSelectedChatRoom = () => {
      setSelectedIdNotification(selectedChatRoomId.value);
      getSelectedIdNotification.value = selectedChatId.getSelectedIdNotification;
    };
    const onCloseSelectedChatRoom = () => {
      setSelectedIdNotification('');
      getSelectedIdNotification.value = selectedChatId.getSelectedIdNotification;
    };

    const onMessageEvent = (event: Event) => {
      const msgEvent = event as MessageEvent;
      if (!eventSource.value) return;
      const newMessage = JSON.parse(msgEvent.data);
      if (newMessage.type === 'CHAT_MESSAGE_CREATED') {
        if (newMessage?.payload?.document?.id) {
          getClientDocumentContentUrl(newMessage?.payload?.document?.id);
        }
        if (
          !newMessage?.payload?.document?.id ||
          (newMessage?.payload?.document?.id && mercureFile.value)
        ) {
          const transformedMessage: TransformedMessage = {
            _id: newMessage?.payload?.chatMessage?.id ?? '',
            senderId: newMessage?.payload?.sender?.id ?? '',
            content: newMessage?.payload?.chatMessage?.content,
            username: newMessage?.payload?.sender?.name,
            sortingDate: newMessage?.payload?.chatMessage?.sentAt,
            avatar: `https://ui-avatars.com/api/?name=${newMessage?.payload?.sender?.name}`,
            date: getFormattedDate(newMessage?.payload?.chatMessage?.sentAt ?? ''),
            timestamp: formatChatTimestamp(newMessage?.payload?.chatMessage?.sentAt) ?? '',
            files: newMessage?.payload?.document ? mercureFile.value : [],
          };

          // Extract first and last name from the full name, not supported in payload
          const nameParts = (newMessage?.payload?.sender?.name || '').split(' ');
          const firstName = nameParts[0] || '';
          const lastName = nameParts.slice(1).join(' ') || '';

          const formattedMessage: ChatMessageFieldsFragment = {
            __typename: 'ChatMessage',
            id: newMessage?.payload?.chatMessage?.id || '',
            content: newMessage?.payload?.chatMessage?.content || '',
            sentAt: newMessage?.payload?.chatMessage?.sentAt || '',
            sender: {
              __typename: 'Account',
              id: newMessage?.payload?.sender?.id || '',
              email: '',
              firstName,
              lastName,
              middleName: null,
              avatar: null,
            },
            document: newMessage?.payload?.document
              ? {
                  __typename: 'ClientCompanyDocument',
                  id: newMessage?.payload?.document?.id || '',
                  title: newMessage?.payload?.document?.title || '',
                  note: newMessage?.payload?.document?.note || null,
                  contentUrl: newMessage?.payload?.document?.contentUrl || null,
                }
              : null,
          };

          addMessageTransformed(transformedMessage, newMessage?.payload?.chatRoom?.id);
          addMessageOriginal(formattedMessage, newMessage?.payload?.chatRoom?.id);
        }

        if (chatRoomId.value !== newMessage?.payload?.chatRoom?.id) {
          if (messageSoundRef.value) {
            messageSoundRef.value?.play();
          }
          addUnreadMessagesCount(newMessage?.payload?.chatRoom?.id);
        }

        selectedChatRoomId.value = newMessage?.payload?.chatRoom?.id;

        if (!chatModuleRoute.value && chatRoomId.value !== newMessage?.payload?.chatRoom?.id) {
          addNotification({
            message: `${newMessage?.payload?.sender?.name} - ${(
              newMessage?.payload?.chatRoom.name || ''
            ).replaceAll('_', '-')} ${getPageTerms('chat')}`,
            description: newMessage?.payload?.chatMessage?.content,
            type: NotificationType.NOTIFY,
            showIcon: true,
            callBackOnClick: onOpenSelectedChatRoom,
          });
        }
      }

      if (
        ['IOI_SUBMITTED', 'OFFER_CREATED', 'OFFER_REJECTED', 'OFFER_ACCEPTED'].includes(
          newMessage.type,
        )
      ) {
        indicationOfInterestNotificationHandler(newMessage);
      }

      if (newMessage.type === 'USER_OPENED_EXCHANGE') {
        bellSoundRef.value?.play();
      }

      // Price accepted is fired for both market and limit orders
      if (newMessage.type === 'PRICE_ACCEPTED') {
        if (!userStore.isClientRole) {
          if (newMessage.payload.order.executionType === 'limit') {
            elephantSoundRef.value?.play();
          } else if (newMessage.payload.order.type === 'buy') {
            bullSoundRef.value?.play();
          } else {
            bearSoundRef.value?.play();
          }
        }
        handleOrderViaWebsocket(newMessage);
      }

      if (newMessage.type === 'PRICE_REJECTED') {
        if (!userStore.isClientRole) {
          messageSoundRef.value?.play();
        }
        handleOrderViaWebsocket(newMessage);
      }

      if (['ORDER_CANCELED', 'ORDER_FILLED'].includes(newMessage.type)) {
        handleOrderViaWebsocket(newMessage);
      }

      if (['ORDER_REQUESTED_TO_CANCEL'].includes(newMessage.type)) {
        if (!userStore.isClientRole) {
          dogCancelledSoundRef.value?.play();
        }
        handleOrderViaWebsocket(newMessage);
      }

      if (newMessage.type === 'ROLL_INPUT_UPDATED') {
        refetchFunctions.value.refetchOrderBook();
        refetchFunctions.value.refetchRollInputs();
      }

      if (newMessage.type === 'EXCHANGE_STATUS_UPDATED') {
        handleExchangeStatus(newMessage.payload.exchangeStatus);
      }

      if (isAllowedNotificationType(newMessage.type)) {
        const newPushNotification = {
          notificationType: newMessage.type,
          processedPayload: newMessage.payload,
          id: newMessage?.notification_id,
          occurred_at: new Date().toISOString(),
        };
        pushNotificationStore.addPushNotification(
          getFormattedNotificationItem(newPushNotification),
        );
      }
    };

    const iconBarOpenValue = computed(() => (isIconBarOpen.value ? 'iconbar-expanded' : ''));

    const handleEventSource = () => {
      const headers = new Headers();
      if (userStore.mercureToken)
        headers.append('Authorization', `Bearer ${userStore.mercureToken}`);
      const currentUserId = userStore?.userProfile?.id ?? '';
      eventSource.value = new FetchEventSource(
        `${import.meta.env.VITE_MERCURE_URL}/.well-known/mercure?topic=${currentUserId}`,
        {
          headers,
        },
      );
      eventSource.value.addEventListener('message', onMessageEvent, false);
      log('Mercure connected');
    };

    watchEffect(() => {
      if (
        !eventSource.value &&
        (isLoggedIn.value || route.path === MAIN_ROUTES.PRODUCT_SWITCHER.path)
      ) {
        handleEventSource();
        // check squidex token, if invalid fetch new one
        if (!userStore.getSquidexToken) {
          fetchSquidexToken();
        } else {
          const decodedToken = jwtDecode(userStore.getSquidexToken);
          if (!decodedToken) {
            fetchSquidexToken();
          }
          if (decodedToken && decodedToken.exp) {
            const now = dayjs();
            const expiryDate = dayjs.unix(decodedToken.exp);
            const oneWeekFromNow = now.add(1, 'week');

            if (expiryDate.isBefore(oneWeekFromNow)) {
              fetchSquidexToken();
            }
          }
        }
      }
    });

    watch(isLoggedIn, (newIsLoggedIn) => {
      if (!newIsLoggedIn && eventSource.value) {
        eventSource.value.close();
        eventSource.value.removeEventListener('message', onMessageEvent, false);
        eventSource.value = null;
      }
    });

    const onOpenChatModule = (chatRoomLength: number) => {
      lengthChatRooms.value = Number(chatRoomLength);
    };

    const showMenu = computed(
      () => isLoggedIn.value && (isMobileMenuOpen.value || !isMobileWindowWidth.value),
    );

    onMounted(() => {
      saveUrl();
      onOpenChatModule(lengthChatRooms.value);
      window.addEventListener('storage', logoutListener);
      window.addEventListener('resize', updateWidth);

      // Track if interaction with the dom has happened
      document.addEventListener('click', trackInteraction, { once: true });
      document.addEventListener('keydown', trackInteraction, { once: true });
    });

    onUnmounted(() => {
      window.removeEventListener('resize', updateWidth);
    });

    watch(lengthChatRooms, (newLengthChatRooms) => {
      if (newLengthChatRooms > 0) {
        const urlQueryParamMode = new URLSearchParams(window.location.search).get('mode');
        if (urlQueryParamMode === 'chat') {
          toggleChat(true);
        }
      }
    });

    return {
      globalStore,
      chatRoomId,
      toggleProductSwitcherPopup,
      isLoggedIn,
      userRole,
      eventSource,
      selectedChatRoomId,
      onOpenSelectedChatRoom,
      getFormattedDate,
      formatChatTimestamp,
      getPageTerms,
      chatModuleRoute,
      isBSuppRole,
      dashboardRoute,
      isTransactionChatPage,
      onOpenChatModule,
      roleClassName,
      messageSoundRef,
      bullSoundRef,
      bellSoundRef,
      bearSoundRef,
      dogCancelledSoundRef,
      elephantSoundRef,
      messageSound,
      bullSound,
      bellSound,
      bearSound,
      dogCancelledSound,
      elephantSound,
      getClientDocumentContentUrl,
      getSelectedIdNotification,
      onCloseSelectedChatRoom,
      route,
      MAIN_ROUTES,
      userStore,
      ViewType,
      iconBarOpenValue,
      isMobileMenuOpen,
      showMenu,
      userNotificationStore,
    };
  },
});
</script>

<template>
  <div ref="app" :class="`app role-${roleClassName()}`">
    <div v-if="userStore.isAPIUserRole && route.path !== MAIN_ROUTES.INVITE.path">
      <top-nav-bar />
      <div class="content-container">
        <router-view :key="route.path" />
      </div>
      <teleport to="body">
        <div class="notification-container">
          <TransitionGroup name="fade">
            <Notification
              v-for="notification in userNotificationStore.getNotifications"
              :key="notification.id"
              :notification="notification"
            />
          </TransitionGroup>
        </div>
      </teleport>
    </div>
    <div v-else :class="{ 'main-container': isLoggedIn, 'mobile-menu-open': isMobileMenuOpen }">
      <Transition v-if="isLoggedIn" name="icon-nav-bar" mode="out-in">
        <nav
          v-show="showMenu"
          data-unit-tests="desktop-menu"
          :class="['nav-cont', iconBarOpenValue]"
        >
          <IconNavBar />
        </nav>
      </Transition>
      <div class="index-container" :class="{ 'dashboard-route': dashboardRoute }">
        <TopClientViewBar
          v-if="userStore.getViewType === ViewType.CLIENT && userStore.getViewCompany?.legalName"
        />
        <top-nav-bar v-if="isLoggedIn" />
        <div
          :class="[
            'content-container',
            {
              'no-padding':
                route.path === MAIN_ROUTES.INVITE.path ||
                route.path === MAIN_ROUTES.PRODUCT_SWITCHER.path ||
                route.path === MAIN_ROUTES.PORTAL_FEATURES.path ||
                route.name === MAIN_ROUTES.PRODUCT_DETAILS.name,
            },
          ]"
        >
          <router-view :key="route.path" />
        </div>
        <teleport to="body">
          <div class="notification-container">
            <TransitionGroup name="fade">
              <Notification
                v-for="notification in userNotificationStore.getNotifications"
                :key="notification.id"
                :notification="notification"
              />
            </TransitionGroup>
          </div>
        </teleport>
        <ChatModule
          v-if="isLoggedIn && !chatModuleRoute && !isTransactionChatPage"
          :get-selected-id-notification="getSelectedIdNotification"
          @on-close-selected-chat-room="onCloseSelectedChatRoom"
          @on-open-chat-module="onOpenChatModule"
        />
      </div>
    </div>
    <BaseModal
      :is-open="globalStore.productSwitcherPopupOpened"
      modal-title=""
      :show-buttons="false"
      :data-cy="`App${getPageTerms('h1Popup').replaceAll(' ', '-')}`"
      modal-style="product-switcher-popup"
      @handle-close="toggleProductSwitcherPopup(false)"
    >
      <ProductSwitcher :popup-mode="true" data-cy="AppProductSwitcher" />
    </BaseModal>
    <div class=".ModalPortal" />
    <div class=".MenuPortal" />
    <div class=".AppRoot" />
  </div>
  <audio ref="messageSoundRef">
    <source :src="messageSound" type="audio/mpeg" />
  </audio>
  <audio ref="bellSoundRef">
    <source :src="bellSound" type="audio/mpeg" />
  </audio>
  <audio ref="bullSoundRef">
    <source :src="bullSound" type="audio/mpeg" />
  </audio>
  <audio ref="bearSoundRef">
    <source :src="bearSound" type="audio/mpeg" />
  </audio>
  <audio ref="dogCancelledSoundRef">
    <source :src="dogCancelledSound" type="audio/mpeg" />
  </audio>
  <audio ref="elephantSoundRef">
    <source :src="elephantSound" type="audio/mpeg" />
  </audio>
</template>

<style lang="scss">
.notification-container {
  position: fixed;
  right: var(--space-xxs);
  bottom: var(--space-xxs);
  z-index: 1001;
}

.main-container {
  display: grid;
  grid-template-columns: auto;
  height: 100vh;

  @media screen and (min-width: $mobile-plus) {
    grid-template-columns: auto 1fr;
  }
}

.modal__container.product-switcher-popup {
  padding: remCalc(32);
  border-radius: 20px;
  max-width: 1200px;
  display: flex;
  flex-direction: column;

  @media screen and (min-width: $tablet-minus) {
    height: 100vh;
  }

  .modal__top-row {
    margin: 0;
  }
  .modal__header {
    position: relative;
  }
  .modal__title {
    color: var(--color-primary);
    width: 100%;
    margin: 0;
    font-size: remCalc(32);
    line-height: 1;
    @extend %fw-500;
  }
  .modal__close {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    svg {
      width: 42px;
      height: 42px;
      fill: var(--color-primary);
    }
  }
  .main-wrapper {
    height: 100%;
    padding: 0;
  }
  &.calendar-mode {
    height: auto;
    .modal__title {
      font-size: remCalc(20);
      @extend %fw-600;
      margin-top: remCalc(14);
    }
  }
}

.nav-cont {
  display: flex;
  width: 65%;
  height: 100vh;
  position: absolute;
  overflow: hidden;
  z-index: 90;

  @media screen and (min-width: $mobile-plus) {
    position: relative;
    width: 100%;
    overflow: auto;
  }
}

// Animation should only be active on mobile
@media screen and (max-width: $mobile-plus) {
  .icon-nav-bar-enter-active,
  .icon-nav-bar-leave-active {
    transition: width 0.25s ease-in-out;
    width: 0;
  }

  .icon-nav-bar-enter, .icon-nav-bar-leave-to /* starting and ending state for enter/leave transitions */ {
    width: 0;
  }

  .icon-nav-bar-enter-to {
    width: 65%;
  }
}

@keyframes slideInUp {
  0% {
    transform: translateY(200%);
  }

  100% {
    transform: translateX(0%);
  }
}
</style>
