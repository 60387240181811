import { acceptHMRUpdate, defineStore } from 'pinia';
import { useStorage } from '@vueuse/core';
import { GlobalStateModel } from '@/types/base-types';

export const useGlobalStore = defineStore('global', {
  state: (): GlobalStateModel => ({
    showGoToCalendlyButton: false,
    headerTitle: '',
    actionButtons: [],
    productSwitcherPopupOpened: false,
    showChat: useStorage('showChat', false),
    isClientViewSelectionModalOpen: false,
  }),

  getters: {
    isGoToCalendlyButtonVisible(state: GlobalStateModel): boolean {
      return state.showGoToCalendlyButton;
    },
    isActionButtonVisible(state: GlobalStateModel): boolean {
      return !!state.actionButtons[0]?.actionButtonId || false;
    },
    getActionButtonState(state: GlobalStateModel) {
      // eslint-disable-next-line max-len
      return (actionButtonId: string) =>
        state.actionButtons.find((el) => el.actionButtonId === actionButtonId)?.actionButtonState ||
        false;
    },
    isChatOpen(state: GlobalStateModel): boolean {
      return state.showChat as boolean;
    },
  },
  actions: {
    addGoToCalendlyButton() {
      this.$patch({
        showGoToCalendlyButton: true,
      });
    },
    removeGoToCalendlyButton() {
      this.$patch({
        showGoToCalendlyButton: false,
      });
    },
    toggleProductSwitcherPopup(state: boolean) {
      this.$patch({
        productSwitcherPopupOpened: state,
      });
    },
    addActionButton(
      link?: string,
      text = '',
      id = '1',
      outlined = false,
      icon = '',
      state = false,
    ) {
      if (this.actionButtons.filter((el) => el.actionButtonId === id)) {
        this.actionButtons.push({
          actionButtonId: id,
          toActionLink: link,
          actionButtonText: text,
          actionButtonIcon: icon,
          actionButtonOutlined: outlined,
          actionButtonState: state,
          toggleButtonState() {
            this.actionButtonState = !this.actionButtonState;
          },
        });
      }
    },
    removeActionButton(id = '1') {
      this.actionButtons = this.actionButtons.filter((el) => el.actionButtonId !== id);
    },
    removeAllActionButtons() {
      this.actionButtons = [];
    },
    toggleButtonState(id = '1') {
      const target = this.actionButtons.filter((obj) => obj.actionButtonId === id);
      if (target.length > 0) {
        target[0].actionButtonState = !target[0].actionButtonState;
      }
    },
    addHeaderTitle(title: string) {
      this.$patch({
        headerTitle: title,
      });
    },
    removeHeaderTitle() {
      this.$patch({
        headerTitle: '',
      });
    },
    toggleChat(state: boolean) {
      this.showChat = state;
    },
    toggleClientViewSelectionModal() {
      this.isClientViewSelectionModalOpen = !this.isClientViewSelectionModalOpen;
    },
  },
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useGlobalStore, import.meta.hot));
}
