<script lang="ts">
import { defineComponent, getCurrentInstance, computed } from 'vue';

import Close32 from '@carbon/icons-vue/es/close/32';
import Add32 from '@carbon/icons-vue/es/add/32';
import Edit32 from '@carbon/icons-vue/es/edit/32';
import Logout32 from '@carbon/icons-vue/es/logout/32';
import EditOff32 from '@carbon/icons-vue/es/edit--off/32';
import CloudDownload32 from '@carbon/icons-vue/es/cloud--download/32';
import Send32 from '@carbon/icons-vue/es/send/32';
import Error32 from '@carbon/icons-vue/es/error/32';
import Save32 from '@carbon/icons-vue/es/save/32';
import DataShare32 from '@carbon/icons-vue/es/data-share/32';
import StackedMove32 from '@carbon/icons-vue/es/watson-health/stacked-move/32';
import Hourglass32 from '@carbon/icons-vue/es/hourglass/32';
import DocumentPdf32 from '@carbon/icons-vue/es/document--pdf/32';
import TableBuilt32 from '@carbon/icons-vue/es/table--built/32';
import TextAlignLeft32 from '@carbon/icons-vue/es/text--align--left/32';
import Download32 from '@carbon/icons-vue/es/download/32';
import Maximize32 from '@carbon/icons-vue/es/maximize/32';
import Checkmark32 from '@carbon/icons-vue/es/checkmark/32';
import Subtract32 from '@carbon/icons-vue/es/subtract/32';
import TrashCan32 from '@carbon/icons-vue/es/trash-can/32';
import Chat32 from '@carbon/icons-vue/es/chat/32';
import RequestQuote32 from '@carbon/icons-vue/es/request-quote/32';
import CalendarHeatMap32 from '@carbon/icons-vue/es/calendar--heat-map/32';
import Calendar32 from '@carbon/icons-vue/es/calendar/32';
import Language32 from '@carbon/icons-vue/es/language/32';
import SolarPanel32 from '@carbon/icons-vue/es/solar-panel/32';
import WindPower32 from '@carbon/icons-vue/es/wind-power/32';
import CertificateCheck32 from '@carbon/icons-vue/es/certificate--check/32';
import CarbonAccounting32 from '@carbon/icons-vue/es/carbon-accounting/32';
import CloseOutline32 from '@carbon/icons-vue/es/close--outline/32';
import EnergyRenewable32 from '@carbon/icons-vue/es/energy--renewable/32';
import PenFountain32 from '@carbon/icons-vue/es/pen--fountain/32';
import Portfolio32 from '@carbon/icons-vue/es/portfolio/32';
import Settings32 from '@carbon/icons-vue/es/settings/32';
import Dashboard32 from '@carbon/icons-vue/es/dashboard/32';
import Template32 from '@carbon/icons-vue/es/template/32';
import Finance32 from '@carbon/icons-vue/es/finance/32';
import Events32 from '@carbon/icons-vue/es/events/32';
import Certificate32 from '@carbon/icons-vue/es/certificate/32';
import Archive32 from '@carbon/icons-vue/es/archive/32';
import InformationSquare32 from '@carbon/icons-vue/es/information--square/32';
import Information32 from '@carbon/icons-vue/es/information/32';
import GroupPresentation32 from '@carbon/icons-vue/es/group--presentation/32';
import ChartClusterBar32 from '@carbon/icons-vue/es/chart--cluster-bar/32';
import SettingsAdjust32 from '@carbon/icons-vue/es/settings--adjust/32';
import CurrencyDollar32 from '@carbon/icons-vue/es/currency--dollar/32';
import Money32 from '@carbon/icons-vue/es/money/32';
import RecentlyViewed32 from '@carbon/icons-vue/es/recently-viewed/32';
import Menu32 from '@carbon/icons-vue/es/menu/32';
import Enterprise32 from '@carbon/icons-vue/es/enterprise/32';
import DataTable32 from '@carbon/icons-vue/es/data-table/32';
import ArrowsHorizontal32 from '@carbon/icons-vue/es/arrows--horizontal/32';
import User32 from '@carbon/icons-vue/es/user/32';
import UserFavorite32 from '@carbon/icons-vue/es/user--favorite/32';
import ChevronUp32 from '@carbon/icons-vue/es/chevron--up/32';
import ChevronDown32 from '@carbon/icons-vue/es/chevron--down/32';
import LicenseThirdParty32 from '@carbon/icons-vue/es/license--third-party/32';
import Search32 from '@carbon/icons-vue/es/search/32';
import ChevronRight32 from '@carbon/icons-vue/es/chevron--right/32';
import ChevronLeft32 from '@carbon/icons-vue/es/chevron--left/32';
import CloudAuditing32 from '@carbon/icons-vue/es/cloud--auditing/32';
import Blog32 from '@carbon/icons-vue/es/blog/32';
import UserMultiple32 from '@carbon/icons-vue/es/user--multiple/32';
import InventoryManagement32 from '@carbon/icons-vue/es/inventory-management/32';
import Help32 from '@carbon/icons-vue/es/help/32';
import Email32 from '@carbon/icons-vue/es/email/32';
import Mobile32 from '@carbon/icons-vue/es/mobile/32';
import SendFilled32 from '@carbon/icons-vue/es/send--filled/32';
import ViewFilled32 from '@carbon/icons-vue/es/view--filled/32';
import Filter32 from '@carbon/icons-vue/es/filter/32';
import FilterEdit32 from '@carbon/icons-vue/es/filter--edit/32';
import Warning32 from '@carbon/icons-vue/es/warning/32';
import WarningAlt32 from '@carbon/icons-vue/es/warning--alt/32';
import Notification32 from '@carbon/icons-vue/es/notification/32';
import NotificationNew32 from '@carbon/icons-vue/es/notification--new/32';
import ArrowUp32 from '@carbon/icons-vue/es/arrow--up/32';
import ArrowDown32 from '@carbon/icons-vue/es/arrow--down/32';
import Renew32 from '@carbon/icons-vue/es/renew/32';
import View32 from '@carbon/icons-vue/es/view/32';
import SendAlt32 from '@carbon/icons-vue/es/send--alt/32';
import ThumbsUp32 from '@carbon/icons-vue/es/thumbs-up/32';
import ThumbsUpFilled32 from '@carbon/icons-vue/es/thumbs-up--filled/32';
import ThumbsDownFilled32 from '@carbon/icons-vue/es/thumbs-down--filled/32';
import CloudUpload32 from '@carbon/icons-vue/es/cloud--upload/32';
import Upload32 from '@carbon/icons-vue/es/upload/32';
import CheckmarkOutline32 from '@carbon/icons-vue/es/checkmark--outline/32';
import CheckmarkFilled32 from '@carbon/icons-vue/es/checkmark--filled/32';
import CloseFilled32 from '@carbon/icons-vue/es/close--filled/32';
import Book32 from '@carbon/icons-vue/es/book/32';
import Notebook32 from '@carbon/icons-vue/es/notebook/32';
import Document32 from '@carbon/icons-vue/es/document/32';
import DataViewAlt32 from '@carbon/icons-vue/es/data--view--alt/32';
import FileStorage32 from '@carbon/icons-vue/es/file-storage/32';
import VideoChat32 from '@carbon/icons-vue/es/video--chat/32';
import VideoAdd32 from '@carbon/icons-vue/es/video--add/32';
import Wallet32 from '@carbon/icons-vue/es/wallet/32';
import Currency32 from '@carbon/icons-vue/es/currency/32';
import Analytics32 from '@carbon/icons-vue/es/analytics/32';
import ChartLine32 from '@carbon/icons-vue/es/chart--line/32';
import StarFilled32 from '@carbon/icons-vue/es/star--filled/32';
import DocumentView32 from '@carbon/icons-vue/es/document--view/32';
import EmailNew32 from '@carbon/icons-vue/es/email--new/32';
import ErrorFilled32 from '@carbon/icons-vue/es/error--filled/32';
import ErrorOutline32 from '@carbon/icons-vue/es/error--outline/32';
import LicenseDraft32 from '@carbon/icons-vue/es/license--draft/32';
import Locked32 from '@carbon/icons-vue/es/locked/32';
import ViewOff32 from '@carbon/icons-vue/es/view--off/32';
import PageFirst32 from '@carbon/icons-vue/es/page--first/32';
import PageLast32 from '@carbon/icons-vue/es/page--last/32';
import StudySkip32 from '@carbon/icons-vue/es/watson-health/study--skip/32';
import Switcher32 from '@carbon/icons-vue/es/switcher/32';
import InProgress32 from '@carbon/icons-vue/es/in-progress/32';
import MisuseOutline32 from '@carbon/icons-vue/es/misuse--outline/32';
import Pending32 from '@carbon/icons-vue/es/pending/32';
import DocumentTasks32 from '@carbon/icons-vue/es/document--tasks/32';
import DocumentDownload32 from '@carbon/icons-vue/es/document--download/32';
import Phone32 from '@carbon/icons-vue/es/phone/32';
import MediaLibrary32 from '@carbon/icons-vue/es/media--library/32';
import Idea32 from '@carbon/icons-vue/es/idea/32';
import IbmBluepay32 from '@carbon/icons-vue/es/ibm--bluepay/32';
import CommunicationUnified32 from '@carbon/icons-vue/es/communication--unified/32';
import IbmDataReplication32 from '@carbon/icons-vue/es/ibm--data-replication/32';
import ChartComboStacked32 from '@carbon/icons-vue/es/chart--combo-stacked/32';
import OverflowMenuVertical32 from '@carbon/icons-vue/es/overflow-menu--vertical/32';
import TextClearFormat32 from '@carbon/icons-vue/es/text--clear-format/32';
import UserActivity32 from '@carbon/icons-vue/es/user--activity/32';
import Play from '@carbon/icons-vue/es/play/32';
import Pause from '@carbon/icons-vue/es/pause/32';
import TimePlot32 from '@carbon/icons-vue/es/time-plot/32';
import Grid32 from '@carbon/icons-vue/es/grid/32';
import ListBoxes32 from '@carbon/icons-vue/es/list--boxes/32';
import RiArrowGoForwardLine from 'vue-remix-icons/icons/ri-arrow-go-forward-line.vue';
import RiArrowGoBackLine from 'vue-remix-icons/icons/ri-arrow-go-back-line.vue';
import RiFormatClear from 'vue-remix-icons/icons/ri-format-clear.vue';
import RiImageLine from 'vue-remix-icons/icons/ri-image-line.vue';
import RiTextWrap from 'vue-remix-icons/icons/ri-text-wrap.vue';
import RiSeparator from 'vue-remix-icons/icons/ri-separator.vue';
import RiDoubleQuotesL from 'vue-remix-icons/icons/ri-double-quotes-l.vue';
import RiCodeBoxLine from 'vue-remix-icons/icons/ri-code-box-line.vue';
import RiListCheck2 from 'vue-remix-icons/icons/ri-list-check-2.vue';
import RiListOrdered from 'vue-remix-icons/icons/ri-list-ordered.vue';
import RiListUnordered from 'vue-remix-icons/icons/ri-list-unordered.vue';
import RiParagraph from 'vue-remix-icons/icons/ri-paragraph.vue';
import RiH6 from 'vue-remix-icons/icons/ri-h-6.vue';
import RiH5 from 'vue-remix-icons/icons/ri-h-5.vue';
import RiH4 from 'vue-remix-icons/icons/ri-h-4.vue';
import RiH3 from 'vue-remix-icons/icons/ri-h-3.vue';
import RiH2 from 'vue-remix-icons/icons/ri-h-2.vue';
import RiH1 from 'vue-remix-icons/icons/ri-h-1.vue';
import RiAlignLeft from 'vue-remix-icons/icons/ri-align-left.vue';
import RiAlignCenter from 'vue-remix-icons/icons/ri-align-center.vue';
import RiAlignRight from 'vue-remix-icons/icons/ri-align-right.vue';
import RiAlignJustify from 'vue-remix-icons/icons/ri-align-justify.vue';
import RiMarkPenLine from 'vue-remix-icons/icons/ri-mark-pen-line.vue';
import RiCodeView from 'vue-remix-icons/icons/ri-code-view.vue';
import RiStrikethrough from 'vue-remix-icons/icons/ri-strikethrough.vue';
import RiItalic from 'vue-remix-icons/icons/ri-italic.vue';
import RiBold from 'vue-remix-icons/icons/ri-bold.vue';
import ArrowLeft from '@carbon/icons-vue/es/arrow--left/32';
import analyze from '@/assets/images/icons/analyze.svg';
import FasterInnovationWithPartners from '@/assets/images/icons/faster-innovation-with-partners.svg';
import chart from '@/assets/images/icons/chart.svg';

export default defineComponent({
  name: 'BaseIcon',
  props: {
    iconName: { type: String, required: true },
    size: { type: Number, default: 32 },
    dataCy: { type: String, required: false, default: '' },
    typeIcon: { type: String, default: 'carbon' },
    classList: { type: Array, default: () => [] },
  },

  setup(props) {
    const cypressDataAttribute = `${getCurrentInstance()?.type.name}-${props?.dataCy ? props.dataCy : props?.iconName}`;

    // Map icon names to their imported components
    const iconsMap = {
      close: Close32,
      add: Add32,
      edit: Edit32,
      logout: Logout32,
      'edit--off': EditOff32,
      'cloud-download': CloudDownload32,
      send: Send32,
      error: Error32,
      save: Save32,
      'data-share': DataShare32,
      'watson-health/stacked-move': StackedMove32,
      hourglass: Hourglass32,
      'document-pdf': DocumentPdf32,
      'table--built': TableBuilt32,
      'text--align-left': TextAlignLeft32,
      download: Download32,
      maximize: Maximize32,
      checkmark: Checkmark32,
      subtract: Subtract32,
      'trash-can': TrashCan32,
      chat: Chat32,
      'request-quote': RequestQuote32,
      'calendar--heat-map': CalendarHeatMap32,
      calendar: Calendar32,
      language: Language32,
      'solar-panel': SolarPanel32,
      'wind-power': WindPower32,
      'certificate--check': CertificateCheck32,
      'carbon-accounting': CarbonAccounting32,
      'close--outline': CloseOutline32,
      'energy--renewable': EnergyRenewable32,
      'pen--fountain': PenFountain32,
      portfolio: Portfolio32,
      settings: Settings32,
      dashboard: Dashboard32,
      template: Template32,
      finance: Finance32,
      events: Events32,
      certificate: Certificate32,
      archive: Archive32,
      'information--square': InformationSquare32,
      information: Information32,
      'group--presentation': GroupPresentation32,
      'chart--cluster-bar': ChartClusterBar32,
      'settings--adjust': SettingsAdjust32,
      'currency--dollar': CurrencyDollar32,
      money: Money32,
      'recently-viewed': RecentlyViewed32,
      menu: Menu32,
      enterprise: Enterprise32,
      'data-table': DataTable32,
      'arrows--horizontal': ArrowsHorizontal32,
      user: User32,
      grid: Grid32,
      'list--boxes': ListBoxes32,
      'user--favorite': UserFavorite32,
      'chevron--up': ChevronUp32,
      'chevron--down': ChevronDown32,
      'license--third-party': LicenseThirdParty32,
      search: Search32,
      'chevron--right': ChevronRight32,
      'chevron--left': ChevronLeft32,
      'cloud--auditing': CloudAuditing32,
      blog: Blog32,
      'user--multiple': UserMultiple32,
      'inventory-management': InventoryManagement32,
      help: Help32,
      email: Email32,
      mobile: Mobile32,
      'send--filled': SendFilled32,
      'view--filled': ViewFilled32,
      filter: Filter32,
      'filter--edit': FilterEdit32,
      warning: Warning32,
      'warning--alt': WarningAlt32,
      notification: Notification32,
      'notification--new': NotificationNew32,
      'arrow--up': ArrowUp32,
      'arrow--down': ArrowDown32,
      renew: Renew32,
      view: View32,
      'send--alt': SendAlt32,
      'thumbs-up': ThumbsUp32,
      'thumbs-up--filled': ThumbsUpFilled32,
      'thumbs-down--filled': ThumbsDownFilled32,
      'cloud--upload': CloudUpload32,
      upload: Upload32,
      'checkmark--outline': CheckmarkOutline32,
      'checkmark--filled': CheckmarkFilled32,
      'close--filled': CloseFilled32,
      book: Book32,
      notebook: Notebook32,
      document: Document32,
      'data--view--alt': DataViewAlt32,
      'file-storage': FileStorage32,
      'video--chat': VideoChat32,
      'video--add': VideoAdd32,
      wallet: Wallet32,
      currency: Currency32,
      analytics: Analytics32,
      'chart--line': ChartLine32,
      'star--filled': StarFilled32,
      'document--view': DocumentView32,
      'document--download': DocumentDownload32,
      'email--new': EmailNew32,
      'error--filled': ErrorFilled32,
      'error--outline': ErrorOutline32,
      'license--draft': LicenseDraft32,
      locked: Locked32,
      'view--off': ViewOff32,
      'page--first': PageFirst32,
      'page--last': PageLast32,
      'watson-health/study-skip': StudySkip32,
      switcher: Switcher32,
      'in-progress': InProgress32,
      'misuse--outline': MisuseOutline32,
      pending: Pending32,
      'document--tasks': DocumentTasks32,
      phone: Phone32,
      'media--library': MediaLibrary32,
      idea: Idea32,
      'ibm--bluepay': IbmBluepay32,
      'user--activity': UserActivity32,
      'communication--unified': CommunicationUnified32,
      'ibm--data-replication': IbmDataReplication32,
      'chart--combo-stacked': ChartComboStacked32,
      'overflow-menu--vertical': OverflowMenuVertical32,
      'text--clear-format': TextClearFormat32,
      play: Play,
      pause: Pause,
      'time-plot': TimePlot32,
      RiArrowGoForwardLine,
      RiArrowGoBackLine,
      RiFormatClear,
      RiImageLine,
      RiTextWrap,
      RiSeparator,
      RiDoubleQuotesL,
      RiCodeBoxLine,
      RiListCheck2,
      RiListOrdered,
      RiListUnordered,
      RiParagraph,
      RiH6,
      RiH5,
      RiH4,
      RiH3,
      RiH2,
      RiH1,
      RiAlignLeft,
      RiAlignCenter,
      RiAlignRight,
      RiAlignJustify,
      RiMarkPenLine,
      RiCodeView,
      RiStrikethrough,
      RiItalic,
      ArrowLeft,
      RiBold,
      analyze,
      'faster-innovation-with-partners': FasterInnovationWithPartners,
      chart,
    };

    // Don't want to make type for iconsmap
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore-next-line
    const dynamicIcon = computed(() => iconsMap[props.iconName]);

    return {
      dynamicIcon,
      cypressDataAttribute,
    };
  },
});
</script>

<template>
  <component
    :is="dynamicIcon"
    :class="['nav-mb__icon', ...classList]"
    :style="[`width: ${size / 16 - 0.5}rem; height: ${size / 16 - 0.5}rem;`]"
    :data-cy="cypressDataAttribute"
  />
</template>
<style lang="scss" scoped>
.nav-mb__icon {
  &.hoverable:hover {
    cursor: pointer;
    opacity: 0.6;
    transition: opacity 0.2s ease-in-out;
  }
}
</style>
