import { ref, computed, provide, inject, Ref } from 'vue';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { ApolloCache, type InMemoryCache } from '@apollo/client/core';
import {
  GetProductCommoditiesQuery,
  useGetOrdersQuery,
  useGetProductCommoditiesQuery,
  type CreateOrderMutation,
} from '@/types/graphql/energyExchange';
import { useUserStore, useNotificationStore } from '@/store';
import {
  Order,
  OrderPaginationInfo,
  type EnergyExchangeOrderFieldsFragment,
} from '@/types/graphql';
import { GET_ORDERS } from '@/api/energyExchange';
import { latestBidAsk } from '@/utils/composable/WebSocketPriceService';
import { fieldTranslationMap, OrderType } from '@/types/energy-exchange';
import { t } from './localeHelper';
import { NotificationType } from '@/types/notification';

const { addNotification } = useNotificationStore();
const userStore = useUserStore();
dayjs.extend(utc);
dayjs.extend(timezone);

// Quote status
export type QuoteState = 'prices' | 'createOrder' | 'confirmOrder' | 'disabled' | 'outsideHours';
export type TOption = 't0' | 't2' | 't4' | 't6' | 't8' | 't10';
export type BuyOrSell = 'buy' | 'sell';
export type LatestCreatedOrder = { orderNumber: string; status: string };
export type TableDataItem = {
  paymentTerm: TOption;
  bidPrice: number | null | undefined;
  askPrice: number | null | undefined;
  enabled: boolean | undefined;
};

export const quoteState = ref<QuoteState>('prices');
export const quoteLoadingState = ref<boolean>(false);
export const selectedT = ref<TOption>('t0');
export const selectedtToApi = computed(() => Number(selectedT.value.substring(1)));
export const buyOrSell = ref<BuyOrSell>('buy');
export const latestCreatedOrder = ref<LatestCreatedOrder | null>(null);
export const selectedRegistry = ref<string>('EFYUIKBG89');
export const selectedVolume = ref<number | null>(null);
export const selectedOrdertype = ref<OrderType>(OrderType.marketOrder);
export const kycStatus = ref({ ready: false, requested: false, loading: true });

// Limit statuses
export const selectedLimitPrice = ref<number | null>(null);
export const limitExpiresAt = ref<string | null>(null);

export const selectedRow = ref<TableDataItem | null>(null);

export const setSelectedRow = (row: TableDataItem | null) => {
  selectedRow.value = row;
};

export function resetOrderForms() {
  selectedVolume.value = null;
  selectedLimitPrice.value = null;
  limitExpiresAt.value = null;
  selectedOrdertype.value = OrderType.marketOrder;
  selectedRow.value = null;
}

export function setQuoteState(newStatus: QuoteState) {
  quoteState.value = newStatus;
}

export function setQuoteLoadingState(quoteLoadingStateParam: boolean) {
  quoteLoadingState.value = quoteLoadingStateParam;
}

export function setLatestCreatedOrder(newOrder: LatestCreatedOrder | null) {
  latestCreatedOrder.value = newOrder;
}

export const setSelectedT = (tOption: TOption) => (selectedT.value = tOption);
export const toggleBuyOrSell = () => {
  buyOrSell.value = buyOrSell.value === 'buy' ? 'sell' : 'buy';
};
export const setBuyOrSell = (buyOrSellOption: BuyOrSell) => (buyOrSell.value = buyOrSellOption);

export const isBuy = computed(() => buyOrSell.value === 'buy');

export const isPricesState = computed(() => quoteState.value === 'prices');
export const isCreateOrderState = computed(() => quoteState.value === 'createOrder');
export const isConfirmOrderState = computed(() => quoteState.value === 'confirmOrder');
export const isDisabledState = computed(() => quoteState.value === 'disabled');
export const isOutsideHoursState = computed(() => quoteState.value === 'outsideHours');

// sorting and filtering of orders
export const activeSortFieldOrderConfirmations = ref<string>('createdAt');
export const sortOrderOrderConfirmations = ref<'ASC' | 'DESC'>('ASC');
export const selectedFilterOrderConfirmations = ref<string>('');
export const pageOrderConfirmations = ref<number>(1);

export const activeSortFieldOpenOrders = ref<string>('createdAt');
export const sortOrderOpenOrders = ref<'ASC' | 'DESC'>('ASC');
export const pageOpenOrders = ref<number>(1);

// Order confirm utils
export const commoditiesList = ref<GetProductCommoditiesQuery | null>(null);
export const selectedCommodity = ref('EU ETS (EUA)'); // commodity name is hardcoded for now, as we don't have commodities select
export const selectedCommodityId = computed(
  () =>
    commoditiesList.value?.commodities?.find((item) => item?.name === selectedCommodity.value)?.id,
);

export const isTradingEnabled = computed(() => {
  if (userStore.getViewType === 'clientView') {
    return userStore.isSARoleInClientView;
  }
  return userStore.isClientRole;
});

export const selectedPrice = computed(() => ({
  askByT: latestBidAsk.value.ask?.[selectedT.value].ask,
  bidByT: latestBidAsk.value.bid?.[selectedT.value].bid,
  price:
    buyOrSell.value === 'buy'
      ? latestBidAsk.value.ask?.[selectedT.value].ask
      : latestBidAsk.value.bid?.[selectedT.value].bid,
}));

export function useProductCommodity() {
  let onCompletionCallback: (() => void) | null = null;

  const { onResult } = useGetProductCommoditiesQuery(
    () => ({
      id: userStore.getSelectedProduct?.id,
    }),
    {
      fetchPolicy: 'cache-first',
    },
  );

  onResult((result) => {
    if (result?.data) {
      commoditiesList.value = result.data;
      if (onCompletionCallback) {
        onCompletionCallback();
      }
    }
  });

  const setOnCompletionCallback = (callback: () => void) => {
    onCompletionCallback = callback;
  };

  return { commoditiesList, setOnCompletionCallback };
}

export const checkOrderStatus = (
  newOrderId: string,
  queryVariables: {
    order?:
      | {
          [x: string]: 'ASC' | 'DESC';
        }[]
      | undefined;
    status_list?: string[] | undefined;
    page: number;
    executionType?: string;
  },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  cache: ApolloCache<any>,
) => {
  const existingOrders = cache.readQuery<{
    orders: { collection: Order[]; paginationInfo: OrderPaginationInfo };
  }>({
    query: GET_ORDERS,
    variables: queryVariables,
  });

  return existingOrders?.orders.collection.find((order) => order.id === newOrderId)?.status;
};

// Order handling
const fetchOrders = async () => {
  const { refetch } = useGetOrdersQuery(
    () => ({
      page: 1,
      ...(selectedFilterOrderConfirmations.value
        ? { status_list: [selectedFilterOrderConfirmations.value] }
        : {}),
      ...(activeSortFieldOrderConfirmations.value !== 'createdAt'
        ? {
            order: [
              {
                [fieldTranslationMap[activeSortFieldOrderConfirmations.value]]:
                  sortOrderOrderConfirmations.value,
              },
            ],
          }
        : {}),
    }),
    { fetchPolicy: 'network-only' },
  );

  await refetch();
};

type createOrderReturnType = NonNullable<CreateOrderMutation['createOrder']>['order'];
export const updateCacheWithNewOrder = (
  cache: ApolloCache<InMemoryCache>,
  newOrder: createOrderReturnType,
  queryVariables: {
    order?:
      | {
          [x: string]: 'ASC' | 'DESC';
        }[]
      | undefined;
    status_list?: string[] | undefined;
    page: number;
    executionType?: string;
  },
) => {
  if (!newOrder) return;
  const existingOrders = cache.readQuery<{
    orders: { collection: Order[]; paginationInfo: OrderPaginationInfo };
  }>({
    query: GET_ORDERS,
    variables: queryVariables,
  });

  if (
    existingOrders &&
    !existingOrders?.orders.collection.find((order) => order.id === newOrder?.id)?.id
  ) {
    const updatedOrders = [newOrder, ...existingOrders.orders.collection];

    cache.writeQuery<{
      orders: {
        collection: EnergyExchangeOrderFieldsFragment[];
        paginationInfo: OrderPaginationInfo;
      };
    }>({
      query: GET_ORDERS,
      data: {
        ...existingOrders,
        orders: {
          ...existingOrders.orders,
          collection: updatedOrders as EnergyExchangeOrderFieldsFragment[],
          paginationInfo: {
            ...existingOrders.orders.paginationInfo,
          },
        },
      },
      variables: queryVariables,
    });
  }

  const handleCompletedOrder = async (newOrderParam: createOrderReturnType) => {
    if (!newOrderParam) return;
    setQuoteLoadingState(false);
    setLatestCreatedOrder({
      orderNumber: newOrderParam.number,
      status: newOrderParam.status,
    });
    addNotification({
      message: t('pages.pushNotifications.priceAcceptedMarketOrder.popupMessage'),
      type: NotificationType.SUCCESS,
      showIcon: true,
    });
    setQuoteState('prices');
  };

  const intervalId = setInterval(async () => {
    const orderStatus = checkOrderStatus(newOrder.id, queryVariables, cache);
    if (orderStatus === 'placed') {
      handleCompletedOrder(newOrder);
      clearInterval(intervalId);
    } else if (orderStatus === 'pending') {
      await fetchOrders();
      if (checkOrderStatus(newOrder.id, queryVariables, cache) === 'placed') {
        handleCompletedOrder(newOrder);
        clearInterval(intervalId);
      }
    } else if (orderStatus === 'canceled') {
      setQuoteLoadingState(false);
      setQuoteState('prices');
      clearInterval(intervalId);
    }
  }, 1500);
};

interface RefetchFunctions {
  refetchOrderBook: () => void;
  refetchRollInputs: () => void;
}

const ROLL_INPUTS_UPDATED_KEY = Symbol('ROLL_INPUTS_UPDATED');

export const provideRollInputsUpdated = () => {
  const refetchFunctions: Ref<RefetchFunctions> = ref({
    refetchOrderBook: () => {},
    refetchRollInputs: () => {},
  });
  provide(ROLL_INPUTS_UPDATED_KEY, refetchFunctions);
  return refetchFunctions;
};

export const useRollInputsUpdated = (): Ref<RefetchFunctions> => {
  const refetchFunctions = inject<Ref<RefetchFunctions>>(ROLL_INPUTS_UPDATED_KEY);
  return refetchFunctions!;
};
